import baseActions from '@/store/shared/graphql/actions'
import baseEvents from '@/store/shared/graphql/events'
import baseMutations from '@/store/shared/graphql/mutations'
import getters from '@/store/shared/graphql/getters'

export default (STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api) => {
    const events = baseEvents(STORE_TYPE)
    const actions = baseActions(API_DATA_BY_ID_KEY, API_DATA_KEY, api, events)
    const mutations = baseMutations(events)
    const state = {
        items: [],
        fetching: false,
        saving: false,
        errors: [],
        pagination: {
            limit: null,
            offset: null,
            totalCount: null,
        },
    }

    return {
        events,
        actions,
        mutations,
        getters,
        state,
    }
}
