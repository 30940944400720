import api from '@/api/imt/account'
import baseModule from '@/store/shared/rest'

const STORE_TYPE = 'ACCOUNT'
const RESOURCE_TYPE = 'account'
const defaultModule = baseModule(STORE_TYPE, RESOURCE_TYPE, api)

export default {
    ...defaultModule,
    namespaced: true,
}
