import { DateTime } from 'luxon'

const ROLLOVER_START_MONTH = 5

export const isoDate = (dateString) => {
    if (dateString) {
        return new Date(dateString).toISOString()
    }

    return dateString
}

/**
 * Display Date from Admin App Server.
 *
 * @param {string} dateString
 * @returns
 */
export const displayDate = (dateString) => {
    if (dateString) {
        return new DateTime.fromISO(dateString).setZone('est').toFormat("MM/dd/yyyy '-' hh:mma")
    }

    return ''
}

/**
 * Admin is special and uses DST, but always offsets as EST. Postgres uses UTC, so we need to account for DST
 * seporately.
 *
 * @param {*} dateString
 * @returns
 */
export const DisplayDatePostgres = (dateString) => {
    if (dateString) {
        return new DateTime.fromISO(dateString)
            .setZone('America/New_York')
            .toFormat("MM/dd/yyyy '-' hh:mma")
    }

    return ''
}

export const displayTime = (date) => {
    if (date) {
        return DateTime.fromISO(date).setZone('est').toFormat('HH:mm')
    }

    return ''
}

export const currentYear = () => {
    return new Date().getFullYear()
}

/**
 * This should return the year for the start of the school year (September / August).
 *  Example
 *    September 20 2020 = 2020
 *    January 5 2021 = 2020
 * @returns {number}
 */
export const currentSchoolYear = () => {
    const date = new Date()
    let year = date.getFullYear()

    //new school year starts in June to allow for rollover stuff
    if (date.getMonth() < ROLLOVER_START_MONTH) {
        year--
    }

    return year
}
//Replace "camelCaseString" with "Camel Case String"
export const humanizeStr = (str) => {
    if (!str) return str

    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
}

/**
 * Replace camelCase, snake_case, kebab-case with Proper/Title Case string
 *  Example
 *  - firstName => First Name
 *  - illuminate_azure => Illuminate Azure
 *  - school-year => School Year
 * @param string str
 * @returns string
 */
export const properCase = (str) => {
    if (!str) return str

    return (
        str
            //replace - _ with space
            .replace(/([-_+])/g, ' ')
            // add space between lower and upper chars
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            //find all words, upper each first char and lower the rest
            .replace(
                /\b\w+/g,
                (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
            )
    )
}

/**
 * determine if system is currently in rollover mode.
 * This is currently defined as being between May and September.
 *
 * @returns true if in rollover season
 */
export const rolloverTime = () => {
    const month = new Date().getMonth()

    return month < 10 && month >= ROLLOVER_START_MONTH
}

export default {
    isoDate,
    currentYear,
    displayDate,
    currentSchoolYear,
    humanizeStr,
    rolloverTime,
}
