import baseMutations from '@/store/shared/rest/mutations'
import baseActions from '@/store/shared/rest/actions'
import baseEvents from '@/store/shared/rest/events'
import getters from '@/store/shared/rest/getters'

const defaultOptions = () => ({
    updateOnPatch: true,
    updateOnPost: true,
})

export default (STORE_TYPE, RESOURCE_TYPE, api, options) => {
    const opts = { ...defaultOptions(), ...options }
    const events = baseEvents(STORE_TYPE)
    const actions = baseActions(RESOURCE_TYPE, api, events, opts)
    const mutations = baseMutations(events)
    const state = {
        item: {},
        items: [],
        metadata: {},
        errors: [],
        fetching: false,
        saving: false,
    }

    return {
        events,
        actions,
        mutations,
        getters,
        state,
    }
}
