import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            let resp = await api.get(`/api/district/staffs`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching staff'
        }
    },
    async patch({ id, lea, data }) {
        try {
            let resp = await api.patch(`/api/district/staff/${id}`, data, { params: { lea: lea } })

            return resp.data
        } catch (err) {
            throw `Error patching staff ${id}`
        }
    },
    async post({ lea, data }) {
        try {
            let resp = await api.post(`/api/district/staff`, data, { params: { lea: lea } })

            return resp.data
        } catch (err) {
            throw `Error posting staff ${lea}`
        }
    },
}
