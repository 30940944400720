import Vue from 'vue'
import setVar from '@/helpers/variable-set'

export const getYears = (startYear, numberOfYears) => {
    let years = []

    for (let i = 0; i < numberOfYears; i++) {
        years.push(startYear)
        startYear--
    }

    return years
}

export const formatYear = (year) =>
    parseInt(year) ? `${parseInt(year)}-${parseInt(year) + 1}` : year

export const getYearTerms = (startYear, numberOfYears) =>
    getYears(startYear, numberOfYears).map(formatYear)

export const sortByField = (sortByField, asc = true) => {
    if (asc) {
        return (a, b) => (a[sortByField] > b[sortByField] ? 1 : -1)
    }

    return (a, b) => (a[sortByField] > b[sortByField] ? -1 : 1)
}

export const getYearObjects = (startYear, numberOfYears) =>
    getYears(startYear, numberOfYears).map((year) => ({
        value: year,
        label: formatYear(parseInt(year)),
    }))

export const randomId = (prefix = 'id_prefix') => {
    return Math.random()
        .toString(24)
        .replace('0.', prefix || '')
}

export const providerNames = [
    { title: 'Azure', provider: 'azure' },
    { title: 'Clever', provider: 'clever' },
    { title: 'ClassLink', provider: 'classlink' },
    { title: 'Google', provider: 'google' },
    { title: 'LDAP', provider: 'ldap' },
    { title: 'SAML', provider: 'saml' },
    { title: 'Local', provider: 'illuminate' },
    { title: 'Internal users', provider: 'illuminate_google' },
]

export const providerNameMap = (provider) =>
    providerNames.find((item) => item.provider === provider).title || ''

export const lowerCase = (str) => (str ? str.charAt(0).toLowerCase() + str.slice(1) : str)

export const upperCase = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : str)

export const confirmUnsavedChanges = () => {
    // check if form is dirty and display confirmation
    // return true if ok, false if cancel
    // return null if the form was not dirty
    if (Vue.prototype.$dirtyForm) {
        const confirmed = confirm('You have unsaved changes. Would you like to continue?')
        if (confirmed) {
            setVar('$dirtyForm', false)
        }

        return confirmed
    }

    return null
}

export const appName = () => {
    const appTLD = process.env.VUE_APP_APP_TLD

    return appTLD === 'com' ? 'Console' : `Console (${appTLD.toUpperCase()})`
}
