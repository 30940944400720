import api from '@/api/user'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'USER'
const API_DATA_KEY = 'users'
const API_DATA_BY_ID_KEY = 'id'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)
const { events } = defaultModule

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
        getById: async ({ commit }, id) => {
            commit(events.FETCHING, true)

            const resp = await api.fetchById(id)
            const user = resp.data[API_DATA_KEY] || []
            const pagination = resp.extensions || {
                limit: null,
                offset: null,
                totalCount: null,
            }

            commit(events.FETCHING, false)
            commit(events.ERRORS, resp.errors)
            commit(events.ADD_ITEM, {
                fieldName: API_DATA_BY_ID_KEY,
                item: user,
            })
            commit(events.PAGINATION, pagination)
        },
    },
    namespaced: true,
}
