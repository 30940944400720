import api, { handleError, FETCHING, SAVING } from '@/api/api'
const API_TYPE = 'user'

export default {
    async fetchById(id) {
        try {
            let resp = await api.get(`/api/users/${id}`)

            return resp.data
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async fetchBy(params) {
        try {
            let resp = await api.get('/api/users', { params })

            return resp.data
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async patch({ id, data }) {
        try {
            let resp = await api.patch('/api/users/' + id, data)

            return resp.data
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
}
