import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            const resp = await api.get(`/api/district`, { params })

            return resp.data
        } catch (err) {
            throw `Error fetching district ${id}`
        }
    },
    async fetchAll() {
        const resp = await api.get(`/api/districts`)

        return resp.data
    },

    async patch({ id, data }) {
        try {
            const resp = await api.patch(`/api/district/${id}`, data)

            return resp.data
        } catch (err) {
            throw `Error patching district ${id}`
        }
    },

    async requestFromCustomer({ id, data, lea }) {
        try {
            const resp = await api.post(`/api/district/${id}/request-from-customer`, data, {
                params: { lea },
            })

            return resp.data
        } catch (err) {
            throw `Error posting district ${id}`
        }
    },
    async post({ lea, data }) {
        try {
            const resp = await api.post(`/api/district`, data, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error posting district ${lea}`
        }
    },
}
