import api from '@/api/imt/instances'
import baseModule from '@/store/shared/rest'

const defaultModule = baseModule('INSTANCE', 'instance', api)

export const { FETCHING, SAVING, ERRORS, SET_ITEMS } = defaultModule.events
export const SET_INSTANCE_PRODUCTS = 'SET_INSTANCE_PRODUCTS'

const state = {
    ...defaultModule.state,
    instanceProducts: {},
}

const getters = {
    ...defaultModule.getters,
    getInstanceProducts: (state) => (instanceId) => state.instanceProducts[instanceId] || [],
}

const mutations = {
    ...defaultModule.mutations,
    [SET_INSTANCE_PRODUCTS]: (state, { products, instanceId }) => {
        state.instanceProducts = { [instanceId]: products }
    },
}

const actions = {
    ...defaultModule.actions,
    getProducts: async ({ commit }, instanceId) => {
        commit(FETCHING, true)
        try {
            const resp = await api.getProducts(instanceId)
            commit(SET_INSTANCE_PRODUCTS, {
                products: resp.data.items.map((p) => p.product),
                instanceId,
            })
            commit(ERRORS, resp.data.errors?.message)
        } catch (err) {
            commit(ERRORS, err)
        } finally {
            commit(FETCHING, false)
        }
    },
    addProducts: async ({ commit, getters }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.addProducts(data)

            commit(SET_INSTANCE_PRODUCTS, {
                products: [
                    ...getters.getInstanceProducts(data.instanceId),
                    ...resp.data.map((i) => i.product),
                ],
                instanceId: data.instanceId,
            })
            const errors = formatErrors(resp)

            commit(ERRORS, errors.join(', '))

            return resp
        } catch (err) {
            commit(ERRORS, err)
        } finally {
            commit(SAVING, false)
        }
    },
    deleteProducts: async ({ commit, getters }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.deleteProducts(data)
            const products = getters
                .getInstanceProducts(data.instanceId)
                .filter((p) => !data.data.includes(p.id))

            commit(SET_INSTANCE_PRODUCTS, {
                products,
                instanceId: data.instanceId,
            })
            const errors = formatErrors(resp)

            commit(ERRORS, errors.join(', '))
        } catch (e) {
            commit(ERRORS, e)
        } finally {
            commit(SAVING, false)
        }
    },
    patchStatus: async ({ commit }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.patchStatus(data)
            const errors = resp.data.error?.message
            commit(ERRORS, errors)
            const item = resp.data['instance'] || {}
            commit(SET_ITEMS, [item])

            return resp.data
        } catch (e) {
            commit(ERRORS, e)
        } finally {
            commit(SAVING, false)
        }
    },
    postSandbox: async ({ commit }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.postSandbox(data)
            const errors = resp.data.error?.message
            commit(ERRORS, errors)
            return resp.data
        } catch (e) {
            commit(ERRORS, e)
        } finally {
            commit(SAVING, false)
        }
    },
}

const formatErrors = (resp) => {
    return resp.data.reduce((acc, curr) => {
        if (curr?.error) {
            acc.push(`Error returned. ${curr.error.code} ${curr.error.message}`)
        }

        return acc
    }, [])
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
