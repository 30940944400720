import baseModule from '@/store/shared/graphql'
import api from '@/api/sis-import-translations'

const STORE_TYPE = 'SIS_IMPORT_TRANSLATIONS'
const API_DATA_KEY = 'importTemplateTranslation'
const API_DATA_BY_ID_KEY = 'id'
export const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
    },
    namespaced: true,
}
