import api from '@/api/student-program'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'STUDENT_PROGRAM'

const API_DATA_KEY = 'program'

const API_DATA_BY_ID_KEY = 'id'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    ...defaultModule,
    namespaced: true,
}
