import api from '@/api/gradebook'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'GRADEBOOK'

const API_DATA_KEY = 'gradebook'

const API_DATA_BY_ID_KEY = 'id'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    ...defaultModule,
    namespaced: true,
}
