import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            const resp = await api.get(`/api/behavior`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching behavior.'
        }
    },
}
