<template>
    <v-app>
        <main-header v-if="hasUser" />
        <fe-side-nav v-if="hasUser" :show="hasUser" :items="navItems" />
        <!--
            :key="" This is used to re-render the component when the key changes (route / districtId)
            https://github.com/vuejs/Discussion/issues/356#issuecomment-336060875
        -->
        <main-body v-if="hasDistrictContext && showLogin" :key="routeContext" />
        <sso-iframes v-if="usingIeAuth" />
    </v-app>
</template>

<script>
import MainHeader from './components/layout/Header'
import MainBody from './components/layout/Body'
import SsoIframes from '@/components/SsoIframes'
import { mapGetters } from 'vuex'

export default {
    components: {
        MainHeader,
        MainBody,
        SsoIframes,
    },
    data: () => ({
        baseNavItems: [
            {
                title: 'Dashboard',
                icon: 'fal fa-tachometer-alt',
                sideNavSection: 'dashboard',
                defaultRoute: 'dashboard',
                requiresDistrict: false,
            },
            {
                title: 'District Management',
                icon: 'fal fa-building',
                sideNavSection: 'district-management',
                defaultRoute: 'district-profile',
                requiresDistrict: true,
            },
            {
                title: 'Site Management',
                icon: 'fal fa-school',
                sideNavSection: 'site-management',
                defaultRoute: 'site-overview',
                requiresDistrict: true,
            },
            {
                title: 'User Management',
                icon: 'fal fa-user-friends',
                sideNavSection: 'user-management',
                defaultRoute: 'user-list',
                requiresDistrict: true,
            },
            {
                title: 'Instance Management',
                icon: 'fal fa-desktop-alt',
                sideNavSection: 'district-instances',
                defaultRoute: 'district-instances',
                requiresDistrict: true,
            },
            {
                title: 'Data Explorer',
                icon: 'fal fa-album-collection',
                sideNavSection: 'data-explorer',
                defaultRoute: 'import-logs',
                requiresDistrict: true,
            },
        ],
        clientNavItems: [
            {
                title: 'Api Setup',
                icon: 'fal fa-cogs',
                sideNavSection: 'client-api-setup',
                defaultRoute: 'client-communication',
                requiresDistrict: true,
            },
        ],
    }),
    computed: {
        ...mapGetters({
            hasDistrict: 'hasDistrict',
            hasUser: 'auth/hasUser',
            authType: 'auth/authType',
            hasRole: 'auth/hasRole',
            districtId: 'getDistrictId',
        }),
        navItems() {
            const items = this.hasRole('ROLE_ILLUMINATE_EMPLOYEE')
                ? this.baseNavItems
                : this.clientNavItems

            return items.reduce((acc, curr) => {
                curr.toRoute = {
                    name: curr.defaultRoute,
                    params: { districtId: this.districtId },
                }

                curr.show = curr.requiresDistrict ? this.hasDistrict : true
                acc.push(curr)

                return acc
            }, [])
        },
        usingIeAuth() {
            return this.hasUser && this.authType === 'ieAuth'
        },
        routeContext() {
            const routingKeys = ['districtId']
            const values = Object.keys(this.$route.params).reduce((acc, curr) => {
                if (routingKeys.includes(curr)) {
                    acc.push(`${curr}${this.$route.params[curr]}`)
                }
                return acc
            }, [])

            return `routingKey_${values.join('')}`
        },
        //if the page is a 'district' page make sure the selected district and the route district match before loading
        hasDistrictContext() {
            const { districtId } = this.$route.params

            return districtId ? parseInt(this.districtId) === parseInt(districtId) : true
        },
        showLogin() {
            //only show the body if its the login page or if its not the login page, then only if the user exists.
            return this.$route.name === 'login' || this.hasUser
        },
    },
}
</script>
