import api, { handleError, FETCHING, SAVING, DELETING } from '@/api/api'

const API_TYPE = 'client'
const BASE_URL = '/api/ied/clients'

export default {
    async cget(params) {
        try {
            return await api.get(`${BASE_URL}`, { params })
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async get(id) {
        try {
            return await api.get(`${BASE_URL}/${id}`)
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async post({ data }) {
        try {
            return await api.post(`${BASE_URL}`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async patch({ id, data }) {
        try {
            return await api.patch(`${BASE_URL}/${id}`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async getProducts(id) {
        try {
            return await api.get(`${BASE_URL}/${id}/products`)
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async addProducts({ clientId, data }) {
        try {
            return await api.post(`${BASE_URL}/${clientId}/products`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async deleteProducts({ clientId, data }) {
        try {
            return await api.delete(`${BASE_URL}/${clientId}/products`, { data })
        } catch (err) {
            return await handleError(err, DELETING, API_TYPE)
        }
    },
    async getPublicClients(search) {
        try {
            return await api.get(`/api/public/clients?${search}`)
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
}
