import api from '@/api/site'
import baseEvents from '@/store/shared/graphql/events'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'SITE'
const API_DATA_KEY = 'site'
const API_DATA_BY_ID_KEY = 'id'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

const events = baseEvents(STORE_TYPE)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
        patchMultiple: async function ({ commit }, data) {
            commit(events.SAVING, true)
            const resp = await api.patchMultiple(data)
            commit(events.ERRORS, resp.errors)
            commit(events.SAVING, false)
        },
    },
    namespaced: true,
}
