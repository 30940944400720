import api from '@/api/schedule'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'SCHEDULE'

// This query was made before we made queries singular.
const API_DATA_KEY = 'schedules'

const API_DATA_BY_ID_KEY = 'id'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    ...defaultModule,
    namespaced: true,
}
