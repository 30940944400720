export const SELECT_DISTRICT = 'SELECT_DISTRICT'

const state = {
    selected: {
        lea: null,
        id: null,
        illuminateClientId: null,
        districtName: null,
    },
}

const getters = {
    getDistrictId: (state) => state.selected?.id,
    getDistrictLea: (state) => state.selected?.lea,
    getIlluminateClientId: (state) => state.selected?.illuminateClientId,
    hasDistrict: (state) => !!state.selected?.id,
    getSelectedDistrict: (state) => state?.selected,
    hasValidDistrictContact: (state) => state.selected.contactEmailAddress?.length > 0,
}

const mutations = {
    [SELECT_DISTRICT]: (state, district) => {
        // Object assign to make sure this is set on existing components.
        state.selected = Object.assign(district)
    },
}

const actions = {
    select: async ({ commit }, district) => {
        commit(SELECT_DISTRICT, district)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
