import api from '@/api/account-sync-logs'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'ACCOUNT_SYNC_LOG'

const API_DATA_KEY = 'accountSyncLogs'

const API_DATA_BY_ID_KEY = 'Timestamp'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default { ...defaultModule, namespaced: true }
