import api from '@/api/imt/clients'
import baseModule from '@/store/shared/rest'

const defaultModule = baseModule('CLIENT', 'client', api)

export const { FETCHING, SAVING, ERRORS, CLEAR_ERRORS } = defaultModule.events
export const SELECT_CLIENT = 'SELECT_CLIENT'
export const RESET_CLIENT = 'RESET_CLIENT'
export const SET_CLIENT_PRODUCTS = 'SET_CLIENT_PRODUCTS'
export const ADD_CLIENT = 'ADD_CLIENT'

export const SET_CLIENT_SUBSCRIPTIONS = 'SET_CLIENT_SUBSCRIPTIONS'

const state = {
    ...defaultModule.state,
    clientProducts: {},
}

const getters = {
    ...defaultModule.getters,
    getClientProducts: (state) => (clientId) => state.clientProducts[clientId] || [],
}

const mutations = {
    ...defaultModule.mutations,
    [SET_CLIENT_PRODUCTS]: (state, { products, clientId }) => {
        state.clientProducts = { [clientId]: products }
    },
    [ADD_CLIENT]: (state, client) => {
        if (!state.items.find((v) => v.id === client.id)) {
            state.items.push(client)
        }
    },
}

const actions = {
    ...defaultModule.actions,
    getProducts: async ({ commit }, clientId) => {
        commit(FETCHING, true)

        try {
            const resp = await api.getProducts(clientId)
            commit(SET_CLIENT_PRODUCTS, {
                products: resp.data.items.map((i) => i.product),
                clientId,
            })
            commit(ERRORS, resp.data.error?.message)
        } catch (e) {
            commit(ERRORS, e)
        }

        commit(FETCHING, false)
    },
    addProducts: async ({ commit, getters }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.addProducts(data)
            // add the new products to selected client
            const { clientId } = data
            commit(SET_CLIENT_PRODUCTS, {
                clientId,
                products: [
                    ...getters.getClientProducts(clientId),
                    ...resp.data.map((i) => i.product),
                ],
            })
            commit(ERRORS, resp.data.error?.message)
        } catch (e) {
            commit(ERRORS, e)
        }

        commit(SAVING, false)
    },
    deleteProducts: async ({ commit, getters }, data) => {
        commit(SAVING, true)

        try {
            const resp = await api.deleteProducts(data)
            // remove the deleted products from selected client
            const { clientId } = data
            commit(SET_CLIENT_PRODUCTS, {
                clientId,
                products: getters
                    .getClientProducts(clientId)
                    .filter((p) => !data.data.includes(p.id)),
            })
            commit(ERRORS, resp.data.error?.message)
        } catch (e) {
            commit(ERRORS, e)
        }

        commit(SAVING, false)
    },
    getPublicClients: async ({ commit }, search) => {
        commit(defaultModule.events.FETCHING, true)

        try {
            const resp = await api.getPublicClients(search)
            commit(defaultModule.events.SET_ITEMS, resp.data.items)
            commit(defaultModule.events.ERRORS, resp.data.error?.message)
        } catch (e) {
            commit(defaultModule.events.ERRORS, e)
        }

        commit(defaultModule.events.FETCHING, false)
    },
    getSubscriptions: async ({ commit }, { client }) => {},
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
