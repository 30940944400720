import AgGridFilters from '@/store/modules/ag-grid-filters'
import { VTooltip } from 'v-tooltip'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'
import account from '@/store/modules/imt/account'
import accountSyncLog from '@/store/modules/account-sync-logs'
import attendance from '@/store/modules/attendance'
import auth from '@/store/modules/auth'
import authentication from '@/store/modules/imt/authentications'
import behavior from '@/store/modules/behavior'
import client from '@/store/modules/imt/clients'
import components from '@/store/modules/sisImportTemplates/components'
import configuration from '@/store/modules/configuration'
import contact from '@/store/modules/contact'
import course from '@/store/modules/course'
import databaseColumnMap from '@/store/modules/database-column-map'
import district from '@/store/modules/district'
import enrollment from '@/store/modules/enrollment'
import exportLogDetails from '@/store/modules/exportLog/details'
import exportLogHeaders from '@/store/modules/exportLog/headers'
import exportLogResults from '@/store/modules/exportLog/results'
import exportMod from '@/store/modules/export'
import grade from '@/store/modules/grade'
import gradebook from '@/store/modules/gradebook'
import gradingPeriod from '@/store/modules/grading-period'
import importLogDetails from '@/store/modules/importLog/details'
import importLogHeaders from '@/store/modules/importLog/headers'
import importLogResults from '@/store/modules/importLog/results'
import instance from '@/store/modules/imt/instance'
import possibleValues from '@/store/modules/sisImportTemplates/possibleValues'
import product from '@/store/modules/imt/products'
import productsEnabled from '@/store/modules/products-enabled'
import role from '@/store/modules/role'
import rollover from '@/store/modules/rollover'
import schedule from '@/store/modules/schedule'
import schoolTypes from '@/store/modules/school-types'
import schoolYear from '@/store/modules/school-year'
import section from '@/store/modules/section'
import selectDistrict from '@/store/modules/select-district'
import sisApiConfig from '@/store/modules/sis-api-config'
import sisImportPossibleValues from '@/store/modules/sis-import-possible-values'
import sisImportTranslations from '@/store/modules/sis-import-translations'
import sisImports from '@/store/modules/sis-imports'
import sisSystem from '@/store/modules/sis-system'
import site from '@/store/modules/site'
import snackbar from '@/store/modules/snackbar'
import staff from '@/store/modules/staff'
import student from '@/store/modules/student'
import studentProgram from '@/store/modules/student-program'
import studentTag from '@/store/modules/student-tag'
import subscriptions from '@/store/modules/imt/subscriptions'
import transcript from '@/store/modules/transcript'
import usStates from '@/store/modules/us-states'
import user from '@/store/modules/users'
import userPreference from '@/store/modules/user-preference'

Vue.use(Vuex)
Vue.use(VeeValidate, { events: 'change' })
Vue.directive('tooltip', VTooltip)

export default new Vuex.Store({
    modules: {
        accountSyncLog,
        account,
        auth,
        attendance,
        components,
        district,
        exportLogHeaders,
        exportLogResults,
        exportLogDetails,
        selectDistrict,
        schedule,
        section,
        staff,
        studentProgram,
        studentTag,
        site,
        client,
        configuration,
        contact,
        course,
        grade,
        gradebook,
        gradingPeriod,
        behavior,
        importLogHeaders,
        importLogResults,
        importLogDetails,
        instance,
        possibleValues,
        product,
        productsEnabled,
        authentication,
        student,
        user,
        userPreference,
        role,
        rollover,
        export: exportMod,
        usStates,
        sisApiConfig,
        sisImports,
        sisImportTranslations,
        sisImportPossibleValues,
        sisSystem,
        schoolTypes,
        schoolYear,
        snackbar,
        subscriptions,
        transcript,
        enrollment,
        databaseColumnMap,
        staffAgGrid: { ...AgGridFilters },
        studentAgGrid: { ...AgGridFilters },
        siteAgGrid: { ...AgGridFilters },
        sisImportAgGrid: { ...AgGridFilters },
    },
})
