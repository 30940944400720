export default (events) => {
    return {
        [events.SET_ITEM]: (state, item) => {
            if (!item) return

            state.item = item
        },
        [events.SET_ITEMS]: (state, items) => {
            if (!items) return

            state.items = Array.isArray(items) ? items : [items]
        },
        [events.SET_METADATA]: (state, metadata) => {
            state.metadata = metadata
        },
        [events.FETCHING]: (state, fetching) => {
            state.fetching = fetching
        },
        [events.SAVING]: (state, saving) => {
            state.saving = saving
        },
        [events.ERRORS]: (state, errors) => {
            if (!errors) return

            state.errors = Array.isArray(errors) ? errors : [errors]
        },
        [events.CLEAR_ERRORS]: (state) => {
            state.errors = []
        },
    }
}
