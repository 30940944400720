import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            let resp = await api.get('/api/district/sites', { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching sites'
        }
    },
    async patch({ lea, id, data }) {
        try {
            let resp = await api.patch(`/api/district/site/${id}`, data, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error patching site ${id}`
        }
    },
    async patchMultiple({ lea, data }) {
        try {
            const resp = await api.patch(`/api/district/site`, data, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error updating sites`
        }
    },
    async post({ lea, data }) {
        try {
            let resp = await api.post(`/api/district/site`, data, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error posting site ${lea}`
        }
    },
}
