import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-material.css'
import '@/styles/beacon-styles.css'
import '@/styles/app.scss'
import '@/styles/font.scss'
import '@/plugins/ag-grid'
import '@/plugins/datadog'
import { EcFrontendLibrary } from '@illuminateeducation/ec-frontend-library'
import '@illuminateeducation/ec-frontend-library/dist/ec-frontend-library.css'

import App from './App.vue'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import router from './router'
import referer from '@/plugins/referer'
import store from './store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/luxon'

Vue.use(EcFrontendLibrary, {})
Vue.prototype.$vuetify = vuetify
//set global form dirty
Vue.prototype.$dirtyForm = false
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(referer)

const init = async () => {
    await store.dispatch('auth/setUser')

    const user = store.getters['auth/getUser']
    const currentClientId = user?.clientId
    const mostRecentClient = localStorage['app:most_recent_client']
    if (currentClientId && mostRecentClient !== currentClientId) {
        localStorage['app:most_recent_client'] = currentClientId
    }

    new Vue({
        router,
        store,
        vuetify,
        beforeMount() {
            //This will redirect the user to login when LOGOUT_USER is triggered. Its important to have it in here
            //because the router will have been mounted
            store.subscribe(async (mutation) => {
                if (mutation.type === 'auth/LOGOUT_USER') {
                    await router.push({ name: 'login' })
                }
            })

            //Present an alert popup if the user refreshes a page with a dirty form.
            window.addEventListener('beforeunload', (event) => {
                if (!Vue.prototype.$dirtyForm) {
                    return
                }

                event.preventDefault()
                // Chrome browser requires returnValue to be set.
                event.returnValue = ''
            })
        },
        render: (h) => h(App),
    }).$mount('#app')
}

;(async () => {
    await init()
})()
