<template>
    <v-menu left bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab small text rounded v-bind="attrs" class="btn-user" v-on="on">
                {{ initials }}
            </v-btn>
        </template>
        <v-card class="mt-2" max-width="230">
            <v-list>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <v-list-item-subtitle>{{ user.ssoUser.email }}</v-list-item-subtitle>
                        <v-list-item-title>{{ user.ssoUser.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <v-list-item-subtitle>Last Login</v-list-item-subtitle>
                        <v-list-item-title>{{ formattedLastLogin }}</v-list-item-title>
                        <template v-if="user.ssoUser.authType === 'ieAuth'">
                            <v-list-item-subtitle>Client</v-list-item-subtitle>
                            <v-list-item-title>{{ user.clientName }}</v-list-item-title>
                        </template>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content class="pb-0">
                        <template>
                            <v-list-item-subtitle>Auth Type</v-list-item-subtitle>
                            <v-list-item-title>{{
                                properCase(user.ssoUser.authType)
                            }}</v-list-item-title>
                        </template>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-btn block class="primary" href="/api/logout"> Logout </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { DisplayDatePostgres as displayDate, properCase } from '@/helpers/form/formatting'
import { mapGetters } from 'vuex'

export default {
    data: () => ({ properCase }),
    computed: {
        ...mapGetters({ user: 'auth/getUser' }),
        formattedLastLogin() {
            return displayDate(this.user.lastLogin)
        },
        initials() {
            const data = this.user.ssoUser
            return data.firstName.substring(0, 1) + data.lastName.substring(0, 1)
        },
    },
}
</script>
