import Vue from 'vue'

export const SET_FILTER_CONFIG = `SET_FILTER_CONFIG`
export const SET_FLOATING_FILTER_DEFINITION = `FLOATING_FILTER_DEF`
export const FILTER_DATA = `FILTER_DATA`
export const SELECTED_FILTERS = `SELECTED_FILTERS`
export const LOADING_FILTERS = `LOADING_FILTERS`
export const RESET_STATE = `RESET_STATE`

export const defaultState = () => {
    return {
        definition: [],
        data: [],
        selectedFilters: {},
        loadingFilters: true,
    }
}

const getters = {
    getDefinition: (state) => state.definition,
    getFilterData: (state) => state.data,
    loadingFilters: (state) => state.loadingFilters,
}

const actions = {
    [SELECTED_FILTERS]: ({ commit }, payload) => {
        commit(SELECTED_FILTERS, payload)
    },
    [SET_FILTER_CONFIG]: ({ commit }, { data, definition }) => {
        commit(RESET_STATE)
        commit(LOADING_FILTERS, true)
        commit(SET_FLOATING_FILTER_DEFINITION, definition)
        commit(FILTER_DATA, data)
        commit(LOADING_FILTERS, false)
    },
    [LOADING_FILTERS]: ({ commit }, payload) => {
        commit(LOADING_FILTERS, payload)
    },
    [RESET_STATE]: ({ commit }) => {
        commit(RESET_STATE)
    },
}

const mutations = {
    [SET_FLOATING_FILTER_DEFINITION]: (state, definition) => {
        Vue.set(state, 'definition', definition)
        state.definition = definition
    },
    [FILTER_DATA]: (state, data) => {
        state.data = data
    },
    [SELECTED_FILTERS]: (state, selectedFilters) => {
        Object.keys(selectedFilters).forEach((key) => {
            if (selectedFilters[key]) {
                state.selectedFilters[key] = selectedFilters[key]
                return
            }

            delete state.selectedFilters[key]
        })
    },
    [LOADING_FILTERS]: (state, payload) => {
        state.loadingFilters = payload
    },
    [RESET_STATE]: (state) => {
        Object.assign(state, defaultState())
    },
}

export default {
    actions,
    mutations,
    getters,
    state: defaultState(),
    namespaced: true,
}
