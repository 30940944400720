import { errorSnackbar } from '@/helpers/snackbar'

export default (RESOURCE_TYPE, api, events, options = {}) => {
    const cget = async ({ commit }, { params = {}, ...config }) => {
        commit(events.FETCHING, true)
        try {
            const resp = await api.cget(params, config)
            const { items, metadata, error = null } = resp.data

            commit(events.SET_ITEMS, items)
            commit(events.SET_METADATA, metadata)
            commit(events.ERRORS, error?.message)

            return items
        } catch (e) {
            commit(events.ERRORS, e)
        } finally {
            commit(events.FETCHING, false)
        }
    }

    const get = async ({ commit }, params) => {
        commit(events.FETCHING, true)
        try {
            const resp = await api.get(params)
            const item = resp.data[RESOURCE_TYPE] || null
            commit(events.SET_ITEM, item)
            commit(events.SET_ITEMS, item)
            commit(events.ERRORS, resp.data.error?.message)
            return item
        } catch (e) {
            commit(events.ERRORS, e)
        } finally {
            commit(events.FETCHING, false)
        }
    }

    const post = async ({ commit }, data) => {
        commit(events.SAVING, true)

        try {
            const resp = await api.post(data)
            commit(events.ERRORS, resp.data.error?.message)
            if (options.updateOnPost) {
                const item = resp.data[RESOURCE_TYPE] || null
                commit(events.SET_ITEMS, item)
            }
            return resp.data
        } catch (e) {
            commit(events.ERRORS, e)
        } finally {
            commit(events.SAVING, false)
        }
    }

    const patch = async ({ commit }, data) => {
        commit(events.SAVING, true)

        try {
            const resp = await api.patch(data)
            commit(events.ERRORS, resp.data.error?.message)
            if (options.updateOnPatch) {
                const item = resp.data[RESOURCE_TYPE] || {}
                commit(events.SET_ITEMS, [item])
            }
            return resp.data
        } catch (e) {
            commit(events.ERRORS, e)
        } finally {
            commit(events.SAVING, false)
        }
    }
    // Delete is a JS reserved word.
    const remove = async ({ commit }, data) => {
        commit(events.SAVING, true)
        const resp = await api.remove(data)
        commit(events.ERRORS, resp.errors)
        commit(events.SAVING, false)

        return resp
    }

    const errors = async ({ commit, getters }) => {
        const errors = getters.getErrors
        errors.forEach(async (e) => await errorSnackbar({ subtext: e }))
        commit(events.CLEAR_ERRORS)
        return errors.length > 0
    }

    const clearErrors = ({ commit }) => {
        commit(events.CLEAR_ERRORS)
    }

    return {
        cget,
        get,
        post,
        patch,
        errors,
        clearErrors,
        remove,
    }
}
