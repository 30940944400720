<template>
    <v-tooltip color="black" bottom transition="transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="hasAccess()"
                icon
                small
                fab
                link
                :to="{ name: routeName }"
                :class="{ 'v-btn--active': activeRoute() }"
                class="mr-4"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
import security from '@/helpers/security'

export default {
    props: {
        routeName: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: 'fas fa-plus',
        },
        tooltipText: {
            type: String,
            default: 'Tooltip text',
        },
        activeRoutes: {
            type: Array,
            default() {
                return []
            },
        },
    },
    methods: {
        activeRoute() {
            return this.activeRoutes.includes(this.$route.name)
        },
        route() {
            return this.$router.resolve({ name: this.routeName })
        },
        hasAccess() {
            return security.accessGranted(this.route().route).length === 0
        },
    },
}
</script>