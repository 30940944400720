import api from '@/api/api'

const BASE_URL = '/api/ied/products'

export default {
    async cget(params) {
        try {
            return await api.get(BASE_URL, { params })
        } catch (err) {
            throw 'Error fetching ied products'
        }
    },
}
