export default {
    getItems: (state) => state.items,
    getMetadata: (state) => state.metadata,
    getErrors: (state) => state.errors,
    getSaving: (state) => state.saving,
    getFetching: (state) => state.fetching,
    getItemById:
        (state) =>
        (id, field = 'id') =>
            state.items.find((item) => item[field] == id),
    getItemsById: (state) =>
        state.items.reduce((acc, cur) => {
            acc[cur.id] = cur

            return acc
        }, {}),
    getItemsByField:
        (state) =>
        (id, field = 'id') =>
            state.items.filter((item) => item[field] == id),
    /**
     * filter items based on a map of field names and values
     *
     * @param fields
     *      fields = { fieldName: value, fieldName2: value }
     * @returns
     */
    getItemsByFields:
        (state) =>
        (fields = {}) =>
            state.items.filter((i) => {
                const foundKeys = Object.keys(fields).filter((key) => {
                    return i[key] == fields[key]
                })

                //make sure the found items match the number of fields we passed in
                return Object.keys(fields).length == foundKeys.length
            }),
}
