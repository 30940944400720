import api from '@/api/api'

const ENDPOINT = '/api/account-sync-logs'

export default {
    async fetchBy(params) {
        const resp = await api.get(`${ENDPOINT}`, { params })
        return resp.data
    },
}
