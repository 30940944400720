import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            let resp = await api.get(`/api/district/sis-import-translations`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching SIS Imports Translations'
        }
    },
    async patch({ id, lea, data }) {
        try {
            let resp = await api.patch(`/api/district/sis-import-translations/${id}`, data, {
                params: { lea },
            })

            return resp.data
        } catch (err) {
            throw `Error patching SIS Import Translations ${id}`
        }
    },
    async post({ lea, data }) {
        try {
            let resp = await api.post(`/api/district/sis-import-translations`, data, {
                params: { lea },
            })

            return resp.data
        } catch (err) {
            throw `Error posting SIS Import Translations ${lea}`
        }
    },
}
