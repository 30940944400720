import api from '@/api/imt/products'
import baseModule from '@/store/shared/rest'

const defaultModule = baseModule('PRODUCT', 'product', api)

const state = {
    ...defaultModule.state,
}

const getters = {
    ...defaultModule.getters,
}

const mutations = {
    ...defaultModule.mutations,
}

const actions = {
    ...defaultModule.actions,
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
