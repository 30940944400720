import Vue from 'vue'

export default (events) => {
    return {
        [events.SET_ITEMS]: (state, items) => (state.items = items),
        //Replace or Add the item to the current items array
        [events.ADD_ITEM]: (state, { fieldName, item }) => {
            const itemIndex = state.items.findIndex((obj) => {
                if (obj[fieldName] === undefined) return false

                return obj[fieldName] === item[fieldName]
            })

            if (itemIndex === -1) {
                state.items = [...state.items, item]
            } else {
                Vue.set(state.items, itemIndex, item)
            }
        },
        // Upsert items into current list.
        [events.UPSERT_ITEMS]: (state, { fieldName, items }) => {
            items.foreach((item) => {
                // if field is undefined (please use a key, but this is just incase), treat it as though it wasn't found. (CAN CAUSE DUPLICATES)
                const idx =
                    item[fieldName] === undefined
                        ? -1
                        : state.items.findIndex((obj) => obj[fieldName] !== item[fieldName])
                if (idx < 0) {
                    state.items[idx] = item
                } else {
                    state.items.push(item)
                }
            })
        },
        [events.FETCHING]: (state, fetching) => (state.fetching = fetching),
        [events.SAVING]: (state, saving) => (state.saving = saving),
        [events.ERRORS]: (state, errors) => (state.errors = errors || []),
        [events.PAGINATION]: (state, pagination) => (state.pagination = pagination),
    }
}
