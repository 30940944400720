export default function install(Vue, options) {
    Vue.mixin({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$router) {
                    vm.$router.referer = from
                }
            })
        },

        beforeRouteUpdate(to, from, next) {
            if (this.$router) {
                //prevent updates to the same route (ag-grid appending url params) from changing the referer
                if (from.name !== from.name) {
                    this.$router.referer = from
                }
            }
            next()
        },
    })
}
