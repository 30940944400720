import baseModule from '@/store/shared/graphql'
import api from '@/api/importLog/results'

const STORE_TYPE = 'IMPORT_LOG_RESULTS'
const API_DATA_KEY = 'activityLogResults'
const API_DATA_BY_ID_KEY = 'etlStatusId'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
    },
    namespaced: true,
}
