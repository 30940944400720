import { datadogRum } from '@datadog/browser-rum'
const appEnv = process.env.VUE_APP_APP_TLD
const datadogEnv = process.env.VUE_APP_DATADOG_ENV

datadogRum.init({
    applicationId: '2efa4517-18fc-4370-9ba4-ab91a51ca023',
    clientToken: 'pub894a5c87e67cf5e7a7b0a8294c501b47',
    site: 'datadoghq.com',
    service: 'console-web-frontend',
    env: datadogEnv || 'development',
    version: '0.1.0',
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [`https://console.illuminateed.${appEnv}`],
})
