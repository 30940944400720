import api from '@/api/sis-api-config'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'SIS_API_CONFIG'
const API_DATA_KEY = 'district'
const API_DATA_BY_ID_KEY = 'id'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default { ...defaultModule, namespaced: true }
