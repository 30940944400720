import authApi from '@/api/auth'

export const USER = 'USER'
export const LOGOUT_USER = 'LOGOUT_USER'

const DEFAULT_USER = {
    id: null,
    emailAddress: null,
    clientId: null,
    accountId: null,
    roles: [],
    reachableRoles: [],
    ssoUser: {
        name: null,
        firstName: null,
        lastName: null,
        authType: null,
    },
}

const state = {
    user: DEFAULT_USER,
}

const getters = {
    getUser: (state) => state.user,
    hasUser: (state) => !!state.user.id,
    // return true if any provided roles are reachable.
    hasRole: (state) => (roles) => {
        if (!Array.isArray(roles)) {
            roles = [roles]
        }

        return state.user.reachableRoles.some((r) => roles.includes(r))
    },
    authType: (state) => state.user.ssoUser.authType,
}

const mutations = {
    [USER]: (state, user) => {
        state.user = user
    },
    [LOGOUT_USER]: (state, user) => {
        state.user = user
    },
}

const actions = {
    setUser: async ({ commit }) => {
        const res = await authApi.fetchUser()
        if (res.data) {
            commit(USER, res.data.user)
        }
    },
    logoutUser: async ({ commit }) => {
        commit(LOGOUT_USER, DEFAULT_USER)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
