import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            let resp = await api.get('/api/export', { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching exports'
        }
    },
    async patch({ lea, id, data, encryptPassword }) {
        try {
            let resp = await api.patch(`/api/export/${id ? id : ''}`, data, {
                params: { lea, encryptPassword },
            })

            return resp.data
        } catch (err) {
            throw `Error patching export ${id ? id : ''}`
        }
    },
    async post({ lea, data }) {
        try {
            const resp = await api.post(`/api/export`, data, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error posting export for ${lea}`
        }
    },

    async remove({ lea, id }) {
        try {
            const resp = await api.delete(`/api/export/${id}`, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error removing export ${id}`
        }
    },
    async exportNow(data) {
        try {
            const resp = await api.post(`/api/export/send-now`, data, {
                params: { lea: data.lea },
            })

            return resp.data
        } catch (err) {
            throw `Error sending export`
        }
    },
}
