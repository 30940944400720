import api from '@/api/api'

export default {
    async get() {
        try {
            return await api.get('/api/user-preferences')
        } catch (err) {
            throw 'Error fetching user preferences'
        }
    },
    async postColStates(data) {
        try {
            return await api.post('/api/user-preferences/column_states', data)
        } catch (err) {
            throw 'Error posting column states'
        }
    },
    async patchColStates(data) {
        try {
            return await api.patch('/api/user-preferences/column_states', data)
        } catch (err) {
            throw 'Error patching column states'
        }
    },
    async postPageSizes(data) {
        try {
            return await api.post('/api/user-preferences/page_sizes', data)
        } catch (err) {
            throw 'Error posting pages sizes'
        }
    },
    async patchPageSizes(data) {
        try {
            return await api.patch('/api/user-preferences/page_sizes', data)
        } catch (err) {
            throw 'Error patching pages sizes'
        }
    },
}
