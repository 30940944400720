import api from '@/api/api'

export default {
    async fetchUser() {
        try {
            return await api.get('/api/user-info')
        } catch (err) {
            return false
        }
    },
}
