import api from '@/api/api'

/**
 * @param endpoint the API endpoint to hit
 * @param subject the noun that the endpoint represents (i.e. Sections, Courses) used in errors.
 */
export default (endpoint, subject) => ({
    async fetchBy(params) {
        try {
            const resp = await api.get(endpoint, { params })

            return resp.data
        } catch (err) {
            throw `Error fetching ${subject}`
        }
    },
})
