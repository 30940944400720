import api from '@/api/district'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'DISTRICT'
const API_DATA_KEY = 'district'
const API_DATA_BY_ID_KEY = 'id'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

const getters = {
    hasMultipleDistricts: (state) => state.items?.length && state.items.length > 1,
}

const actions = {
    getAll: async ({ commit }) => {
        commit(defaultModule.events.FETCHING, true)
        const resp = await api.fetchAll()
        commit(defaultModule.events.SET_ITEMS, resp.data[API_DATA_KEY])
        commit(defaultModule.events.FETCHING, false)

        return resp
    },
    requestFromCustomer: async ({ commit }, data) => {
        const META_TAGS = ['extensions', 'errors']
        commit(defaultModule.events.SAVING, true)
        const resp = await api.requestFromCustomer(data)
        Object.entries(resp.data)
            .filter(([key, _]) => !META_TAGS.includes(key))
            .forEach(([_, item]) => {
                if (!item) return

                commit(defaultModule.events.ADD_ITEM, {
                    fieldName: API_DATA_BY_ID_KEY,
                    item: item,
                })
            })
        commit(defaultModule.events.ERRORS, resp.errors)
        commit(defaultModule.events.SAVING, false)

        return resp
    },
}

export default {
    events: {
        ...defaultModule.events,
    },
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
        ...getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
        ...actions,
    },
    namespaced: true,
}
