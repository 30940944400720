import api from '@/api/api'

export default {
    async fetch() {
        try {
            let resp = await api.get('/api/roles')

            return resp.data
        } catch (err) {
            throw 'Error fetching roles'
        }
    },
    async get(id) {
        try {
            let resp = await api.get(`/api/roles/${id}`)

            return resp.data
        } catch (err) {
            throw 'Error fetching roles'
        }
    },
    async cget(params) {
        try {
            let resp = await api.get(`/api/roles`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching roles'
        }
    },
    async patch({ id, data }) {
        try {
            return await api.patch(`/api/roles/${id}`, data)
        } catch (err) {
            throw 'Error patching roles'
        }
    },
    async post({ data }) {
        try {
            return await api.post(`/api/roles`, data)
        } catch (err) {
            throw 'Error posting roles'
        }
    },
}
