import api from '@/api/sis-system'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'SIS_SYSTEM'
const API_DATA_KEY = 'sisSystem'
const API_DATA_BY_ID_KEY = 'id'

const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
        validateApi: async ({}, payload) => {
            try {
                // this is a really silly way of getting polymorphism to work in a language that doesn't do a good job
                // of supporting it.
                // if there's a validator for the api, validate the API. If not, we'll just return undefined.
                const sisApi = require(`@/api/sis/${payload.provider.name.toLowerCase()}`).default
                return await sisApi.validateApi(payload)
            } catch (e) {
                return undefined // we don't know if the credentials are correct.
            }
        },
    },
    namespaced: true,
}
