import api from '@/api/api'

export default {
    async validateApi({ data }) {
        try {
            const resp = await api.post(`/api/qmlativ/oauth`, {
                baseAPI: data.sisApiUrl,
                key: data.sisApiKey,
                secret: data.sisApiSecret,
            })
            // if errors exist in the api call
            if (resp.data.errors) {
                return false
            }

            return true
        } catch (e) {
            return false
        }
    },
}
