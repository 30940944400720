<template>
    <v-snackbar
        v-if="getDisplay"
        v-model="showMessage"
        right
        :top="getTop"
        :color="getColor"
        :timeout="getDelay"
        max-width="340"
        transition="slide-x-reverse-transition"
    >
        <v-row class="py-4">
            <v-col v-if="getIcon" cols="2" class="d-flex align-center justify-end pr-0 pl-6">
                <v-icon :color="getIconColor">{{ getIcon }}</v-icon>
            </v-col>
            <v-col>
                <p class="snackbar__text">
                    {{ getMessageText }}
                </p>
                <p class="snackbar__subtext">
                    <em>{{ getMessageSubtext }}</em>
                </p>
            </v-col>
        </v-row>

        <template v-if="getDismissable" v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="hide">
                <v-icon color="rgba(192, 195, 207, 1)">fal fa-times</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { MESSAGE_DISPLAY, SHOW_SNACKBAR_MESSAGE } from '@/store/modules/snackbar'

export default {
    data: () => ({
        showMessage: false,
    }),
    computed: {
        ...mapGetters('snackbar', [
            'getMessageText',
            'getDelay',
            'getColor',
            'getDisplay',
            'getDismissable',
            'getMessageSubtext',
            'getIcon',
            'getIconColor',
            'getTop',
        ]),
    },
    mounted() {
        this.$store.subscribe((mutation) => {
            if (mutation.type === `snackbar/${MESSAGE_DISPLAY}`) {
                this.showMessage = this.getDisplay
            }
        })
    },
    methods: {
        hide() {
            this.$store.dispatch(`snackbar/${SHOW_SNACKBAR_MESSAGE}`, {
                display: false,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.snackbar {
    &__text {
        font-weight: 700;
        margin-bottom: 0;
        color: white;
    }
    &__subtext {
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 0;
        color: white;
        inline-size: 210px;
        overflow-wrap: break-word;
    }
}
</style>
