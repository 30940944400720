<template>
    <v-autocomplete
        ref="districtPicker"
        v-model="selectedDistrict"
        class="ma-2"
        :items="districts"
        item-text="districtName"
        item-value="lea"
        hide-details
        single-line
        solo-inverted
        flat
        :append-icon="hasMultipleDistricts ? '$dropdown' : null"
        return-object
        :clearable="false"
        label="District Select"
        :disabled="!hasMultipleDistricts || !hasUser"
        :loading="fetching"
        data-cy="district-picker"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { confirmUnsavedChanges } from '@/helpers/shared'

export default {
    name: 'DistrictPicker',
    computed: {
        ...mapGetters({
            getDistrictById: 'district/getItemById',
            districts: 'district/getItems',
            hasMultipleDistricts: 'district/hasMultipleDistricts',
            fetching: 'district/getFetching',
            hasUser: 'auth/hasUser',
            getSelectedDistrict: 'getSelectedDistrict',
        }),
        selectedDistrict: {
            get() {
                return this.getSelectedDistrict
            },
            async set(district) {
                const districtId = district?.id
                if (!districtId) {
                    return
                }
                if (districtId === this.selectedDistrict?.id) {
                    return
                }

                const newRoute = this.$router.resolve({
                    name: this.$route.name,
                    params: { ...this.$route.params, districtId },
                })

                if (newRoute.href === this.$route.path) {
                    // check for unsaved changes
                    if (confirmUnsavedChanges() === false) {
                        // if cancelled, reset the district picker
                        this.$refs.districtPicker.setValue(this.selectedDistrict)
                        return
                    }
                } else {
                    try {
                        await this.$router.push(newRoute.href)
                    } catch (err) {
                        // reset the district picker
                        this.$refs.districtPicker.setValue(this.selectedDistrict)
                        return
                    }
                }

                if (district?.illuminateClientId) {
                    await this.getDistrictClient(district.illuminateClientId)
                }

                this.selectDistrict(district)
            },
        },
        routeDistrict() {
            return this.$route.params.districtId
        },
    },
    watch: {
        routeDistrict() {
            if (this.getDistrictById(this.routeDistrict)) {
                this.selectDistrict(this.getDistrictById(this.routeDistrict))
            }
        },
        districts() {
            if (!this.getSelectedDistrict && this.getDistrictById(this.routeDistrict)) {
                this.selectDistrict(this.getDistrictById(this.routeDistrict))
            }
        },
    },
    async mounted() {
        await this.getDistricts()

        if (this.$route.params.districtId) {
            this.selectedDistrict = this.getDistrictById(this.$route.params.districtId)
        }
    },
    methods: {
        ...mapActions({
            selectDistrict: 'select',
            getDistricts: 'district/getAll',
            getDistrictClient: 'client/get',
        }),
    },
}
</script>
