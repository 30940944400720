export const SET_ITEMS = '_SET_ITEMS'
export const FETCHING = '_FETCHING'
export const ADD_ITEM = '_ADD_ITEM'
export const PAGINATION = '_PAGINATION'
export const SAVING = '_SAVING'
export const ERRORS = '_ERRORS'

export default (STORE_TYPE) => {
    return {
        FETCHING: `${STORE_TYPE}${FETCHING}`,
        SAVING: `${STORE_TYPE}${SAVING}`,
        SET_ITEMS: `${STORE_TYPE}${SET_ITEMS}`,
        ADD_ITEM: `${STORE_TYPE}${ADD_ITEM}`,
        ERRORS: `${STORE_TYPE}${ERRORS}`,
        PAGINATION: `${STORE_TYPE}${PAGINATION}`,
    }
}
