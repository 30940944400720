<template>
    <v-app-bar app dense flat dark clipped-left :height="48" class="primary">
        <v-img
            src="@/assets/app-logos/Console_White.svg"
            class="mr-2"
            alt="Site Logo"
            max-width="26"
        />
        <v-toolbar-title class="mr-5 title font-weight-bold">{{ appName }}</v-toolbar-title>
        <v-row v-if="hasUser">
            <v-col cols="12" sm="6" md="6" class="py-0">
                <DistrictPicker />
            </v-col>
            <v-col v-if="rolloverTime" cols="12" sm="6" md="6" class="py-0 d-flex align-center"
                >Data Year: {{ district.sisYear | formatYear }}</v-col
            >
        </v-row>

        <v-spacer></v-spacer>
        <div v-if="hasUser">
            <header-button
                icon="fas fa-users"
                tooltip-text="Users and Roles"
                route-name="users"
            ></header-button>
            <header-button
                tooltip-text="Add District"
                route-name="district-profile-add"
            ></header-button>
            <header-button
                :active-routes="['services']"
                route-name="imt-clients"
                icon="fas fa-network-wired"
                tooltip-text="Instance Management"
            ></header-button>
            <header-user-menu></header-user-menu>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import DistrictPicker from '@/components/DistrictPicker'
import HeaderButton from '@/components/layout/HeaderButton'
import HeaderUserMenu from '@/components/layout/HeaderUserMenu'
import { formatYear } from '@/helpers/shared'
import { rolloverTime } from '@/helpers/form/formatting'
import { appName } from '@/helpers/shared'

export default {
    name: 'LayoutHeader',
    components: {
        DistrictPicker,
        HeaderButton,
        HeaderUserMenu,
    },
    filters: {
        formatYear: formatYear,
    },
    data: () => ({
        rolloverTime: rolloverTime(),
        appName: appName(),
    }),
    computed: {
        ...mapGetters('auth', ['hasUser']),
        ...mapGetters({ district: 'getSelectedDistrict' }),
    },
}
</script>
