import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            const resp = await api.get('/api/sis-systems', { params })
            return resp.data
        } catch (err) {
            throw 'Error fetching sis apis'
        }
    },
}
