import api from '@/api/configuration'

const UPDATE_CONFIGURATIONS = 'UPDATE_CONFIGURATIONS'
const UPDATE_LOADING = 'UPDATE_LOADING'

/**
 * Could this have just used the shared graphql query? Maybe. I think there's utility in
 * having this be customized.
 *
 *  + Configuration values aren't very likely to change. Cacheing them is a win,
 *    but they'll also be wiped on refresh to allow on the fly changes if required.
 *  + The data is explicitly meant to be treated as a key value store, rather than a RDB
 *  + The graphQL mutations are set up to preserve order from the source. This doesn't
 *    need to care about that, so there are some efficiencies we can gain by using a KV
 *    data type
 */
export default {
    state: {
        configurations: {},
        loading: {},
    },

    getters: {
        getConfiguration: (state) => (resource) => state.configurations[resource] ?? null,
        getLoading: (state) => (resource) => state.loading[resource] ?? false,
    },

    mutations: {
        [UPDATE_CONFIGURATIONS]: (state, { key, configuration }) => {
            // reactively assign configuration state.
            state.configurations = Object.assign({}, state.configuration, {
                [key]: configuration,
            })
        },
        [UPDATE_LOADING]: (state, { key, value }) => {
            state.loading = Object.assign({}, state.loading, {
                [key]: value,
            })
        },
    },

    actions: {
        async get({ commit }, resource) {
            commit(UPDATE_LOADING, { key: resource, value: true })
            const res = await api.get(resource)
            // returns an array, get the value from the first element.
            const config = res.data.configuration.pop()?.value
            commit(UPDATE_CONFIGURATIONS, { key: resource, configuration: config })
            commit(UPDATE_LOADING, { key: resource, value: false })
        },
    },

    namespaced: true,
}
