import api from '@/api/api'

export const rolloverTemplates = async (params) => {
    const resp = await api.post(`/api/rollover/templates`, params)

    return resp.data.data.rolloverTemplates
}

export const rolloverStaff = async (params) => {
    const resp = await api.post(`/api/rollover/staff`, params)

    return resp.data.data.rolloverStaff
}

export const rolloverExports = async (params) => {
    const resp = await api.post(`/api/rollover/exports`, params)

    return resp.data.data.rolloverExports
}
