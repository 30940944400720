import api from '@/api/sis-imports'
import baseModule from '@/store/shared/graphql'
import { defaultModule as possibleValuesDefaultModule } from '@/store/modules/sis-import-possible-values'
import { defaultModule as translationDefaultModule } from '@/store/modules/sis-import-translations'

const STORE_TYPE = 'SIS_IMPORTS'
const API_DATA_KEY = 'importTemplate'
const API_DATA_BY_ID_KEY = 'id'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,

        //This will return a bunch of nested data from the importTemplate endpoint (translations, possible values, etc)
        getNested: async ({ commit }, payload) => {
            commit(defaultModule.events.FETCHING, true)
            const resp = await api.fetchByDetailed(payload)
            const items = resp.data[API_DATA_KEY] || []

            const pagination = resp.extensions || {
                limit: null,
                offset: null,
                totalCount: null,
            }

            const importTemplateColumns = items.map((item) => item.importTemplateColumns).flat()
            const possibleValues = importTemplateColumns.map((item) => item.possibleValues).flat()
            const importTemplateTranslations = possibleValues
                .map((item) => item.importTemplateTranslations)
                .flat()
            commit(
                `sisImportTranslations/${translationDefaultModule.events.SET_ITEMS}`,
                importTemplateTranslations,
                { root: true }
            )
            commit(
                `sisImportPossibleValues/${possibleValuesDefaultModule.events.SET_ITEMS}`,
                possibleValues,
                { root: true }
            )

            commit(defaultModule.events.FETCHING, false)
            commit(defaultModule.events.ERRORS, resp.errors)
            commit(defaultModule.events.SET_ITEMS, items)
            commit(defaultModule.events.PAGINATION, pagination)
        },
        sisSyncNow: async ({ commit }, payload) => {
            commit(defaultModule.events.SAVING, true)

            const resp = await api.syncNow(payload)

            commit(defaultModule.events.ERRORS, resp.errors)
            commit(defaultModule.events.SAVING, false)

            return resp
        },
    },
    namespaced: true,
}
