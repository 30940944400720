import store from '@/store/index'

/**
 * Checks if the user has access to the route. Returns the missing roles if access is denied.
 * Returns an empty array if access is granted
 *
 * @param route
 * @returns []
 */
const accessGranted = (route) => {
    //check all matched routes (parents)
    let requiredRoles = route.matched.reduce((acc, curr) => {
        if (curr.meta.securityRoles) {
            acc.push(...curr.meta.securityRoles)
        }

        return acc
    }, [])

    let missingRoles = []
    //if there are no required roles then grant access
    if (!requiredRoles || requiredRoles.length === 0) {
        return missingRoles
    }
    const user = store.state.auth.user
    const userRoles = user.reachableRoles

    requiredRoles.forEach((role) => {
        if (!userRoles.includes(role)) {
            missingRoles.push(role)
        }
    })

    return missingRoles
}

/**
 * @deprecated use hasRole from the auth vuex module instead (src/store/modules/auth.js)
 *
 * @param role
 * @returns {boolean}
 */
const hasRole = (role) => {
    let valid = false
    const user = store.state.auth.user
    if (!user.id) {
        return valid
    }
    const userRoles = user.reachableRoles

    userRoles.forEach((userRole) => {
        if (userRole === role) {
            valid = true
        }
    })

    return valid
}

export default {
    accessGranted,
    hasRole,
}
