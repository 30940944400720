import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            let resp = await api.get(`/api/district/sis-imports`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching SIS Imports'
        }
    },
    async fetchByDetailed(params) {
        try {
            let resp = await api.get(`/api/district/sis-imports-detailed`, {
                params,
            })

            return resp.data
        } catch (err) {
            throw 'Error fetching SIS Imports'
        }
    },
    async patch({ id, lea, data }) {
        try {
            let resp = await api.patch(`/api/district/sis-imports/${id}`, data, {
                params: { lea: lea },
            })

            return resp.data
        } catch (err) {
            throw `Error patching SIS Imports ${id}`
        }
    },
    async post({ lea, data }) {
        try {
            let resp = await api.post(`/api/district/sis-imports`, data, {
                params: { lea: lea },
            })

            return resp.data
        } catch (err) {
            throw `Error posting SIS Imports ${lea}`
        }
    },
    async syncNow({ lea, data }) {
        try {
            let resp = await api.post(`/api/district/sis-imports/sync`, data, {
                params: { lea: lea },
            })
            return resp.data
        } catch (err) {
            throw `Error initiating import. ${err}`
        }
    },
    async remove({ lea, id }) {
        try {
            const resp = await api.delete(`/api/district/sis-imports/${id}`, { params: { lea } })

            return resp.data
        } catch (err) {
            throw `Error removing template ${id}`
        }
    },
}
