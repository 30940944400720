<template>
    <div>
        <iframe
            id="rp_session_iframe"
            :src="rp_session_iframe_src"
            style="border: 0"
            height="0"
            width="0"
        ></iframe>
        <iframe
            id="op_session_iframe"
            :src="op_session_iframe_src"
            style="border: 0"
            height="0"
            width="0"
        ></iframe>
    </div>
</template>

<script>
export default {
    data: () => ({
        rp_session_iframe_src: '/api/sso/session',
    }),
    computed: {
        op_session_iframe_src() {
            const appTLD = process.env.VUE_APP_APP_TLD
            return `https://auth.illuminateed.${appTLD}/check_session`
        },
    },
}
</script>