import { LicenseManager } from '@ag-grid-enterprise/core'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'

ModuleRegistry.registerModules([
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    ServerSideRowModelModule,
    ClientSideRowModelModule,
    CsvExportModule,
    SetFilterModule,
    RowGroupingModule,
    SideBarModule,
    ClipboardModule,
    RangeSelectionModule,
])

LicenseManager.setLicenseKey(
    'CompanyName=Illuminate Education,LicensedGroup=Beacon,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=2,AssetReference=AG-009243,ExpiryDate=3_August_2021_[v2]_MTYyNzk0NTIwMDAwMA==8aa41439ff35c00ef5c6a3175a811533'
)
