export default (API_DATA_BY_ID_KEY, API_DATA_KEY, api, events) => {
    // explicitly these fields are not a response to capture as an item.
    const META_TAGS = ['extensions', 'errors']

    const get = async ({ commit }, payload) => {
        commit(events.FETCHING, true)

        const resp = await api.fetchBy(payload)
        const items = resp.data[API_DATA_KEY] || []
        const pagination = resp.extensions || {
            limit: null,
            offset: null,
            totalCount: null,
        }

        commit(events.FETCHING, false)
        commit(events.ERRORS, resp.errors)
        commit(events.SET_ITEMS, items)
        commit(events.PAGINATION, pagination)

        return resp
    }

    const post = async ({ commit }, data) => {
        commit(events.SAVING, true)
        const resp = await api.post(data)
        Object.entries(resp.data)
            .filter(([key, _]) => !META_TAGS.includes(key))
            .forEach(([_, item]) => {
                if (!item) return

                commit(events.ADD_ITEM, {
                    fieldName: API_DATA_BY_ID_KEY,
                    item: item,
                })
            })
        commit(events.ERRORS, resp.errors)
        commit(events.SAVING, false)

        return resp
    }

    const patch = async ({ commit }, data) => {
        commit(events.SAVING, true)
        const resp = await api.patch(data)
        Object.entries(resp.data)
            .filter(([key, _]) => !META_TAGS.includes(key))
            .forEach(([_, item]) => {
                if (!item) return

                commit(events.ADD_ITEM, {
                    fieldName: API_DATA_BY_ID_KEY,
                    item: item,
                })
            })
        commit(events.ERRORS, resp.errors)
        commit(events.SAVING, false)

        return resp
    }

    // Delete is a JS reserved word.
    const remove = async ({ commit }, data) => {
        commit(events.SAVING, true)
        const resp = await api.remove(data)
        commit(events.ERRORS, resp.errors)
        commit(events.SAVING, false)

        return resp
    }

    const removeByParams = async ({ commit, state }, params) => {
        commit(events.SAVING, true)
        const mutations = state.items.reduce((acc, curr) => {
            // compare parameters
            for (const param in params) {
                // if the parameters don't match, we want to eject early.
                if (params[param] !== curr[param]) {
                    return acc
                }
            }
            acc.push(api.remove(curr))

            return acc
        }, [])
        await Promise.all(mutations).then((resps) => {
            const errors = resps.reduce((acc, resp) => {
                if (resp.errors) {
                    acc.push(...resp.errors)
                }
                return acc
            })
            commit(events.ERRORS, errors)
        })
        commit(events.SAVING, false)
    }

    return {
        get,
        post,
        patch,
        remove,
        removeByParams,
    }
}
