import baseModule from '@/store/shared/graphql'
import api from '@/api/database-column-map'
import { sortByField } from '@/helpers/shared'

const STORE_TYPE = 'DATABASE_COLUMN_MAN'
const API_DATA_KEY = 'databaseColumnMap'
const API_DATA_BY_ID_KEY = 'componentName'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
        getSortedItems: (state) => {
            return state.items.sort(sortByField('isRequired', false))
        },
        getColumnMapByField: (state) => (fieldName, fieldValue) =>
            state.items.find((item) => item[fieldName] === fieldValue),
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        get: defaultModule.actions.get,
    },
    namespaced: true,
}
