import api, { handleError, FETCHING, SAVING, DELETING } from '@/api/api'

const CLIENT_HEADER = 'X-Ied-Client-Id'
const API_TYPE = 'remote authentication'
const BASE_URL = '/api/ied/authentications'

export default {
    async cget(params, { clientId }) {
        try {
            return await api.get(`${BASE_URL}/`, {
                headers: {
                    [CLIENT_HEADER]: clientId,
                },
            })
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async get({ clientId }) {
        try {
            return await api.get(`${BASE_URL}/`, {
                headers: {
                    [CLIENT_HEADER]: clientId,
                },
            })
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async post({ clientId, data }) {
        try {
            return await api.post(`${BASE_URL}/`, data, {
                headers: {
                    [CLIENT_HEADER]: clientId,
                },
            })
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async patch({ clientId, id, data }) {
        try {
            return await api.patch(`${BASE_URL}/${id}`, data, {
                headers: {
                    [CLIENT_HEADER]: clientId,
                },
            })
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async remove({ clientId, id }) {
        try {
            return await api.delete(`${BASE_URL}/${id}`, {
                headers: {
                    [CLIENT_HEADER]: clientId,
                },
            })
        } catch (err) {
            return await handleError(err, DELETING, API_TYPE)
        }
    },

    async fetchMetadata(params) {
        try {
            return await api.get(`${BASE_URL}/remote`, { params })
        } catch (err) {
            return await handleError(err, FETCHING, 'authentication metadata')
        }
    },
}
