import api from '@/api/api'

export default {
    async cget(params) {
        try {
            return await api.get(`/api/ied/subscriptions`, { params })
        } catch (err) {
            throw 'Error fetching subscriptions'
        }
    },
}
