import store from '@/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackPrefetch: true */ '@/views/Login/Login'),
        meta: {
            requiresAuth: false,
            hideForAuth: true,
            securityRoles: [],
        },
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackPrefetch: true */ '@/views/Dashboard'),
        meta: {
            requiresAuth: true,
            securityRoles: [],
            sideNavSection: 'dashboard',
        },
    },
    {
        /*
         * Temporary route to redirect clients to the api credential page. A redirect will allow us to
         * get the district Id from the user before loading the page
         */
        path: '/client-communication',
        name: 'client-communication-redirect',
        redirect: (to) => {
            const districtId = store.getters.getDistrictId
            if (!districtId) {
                return '/'
            }

            return {
                name: 'client-communication',
                securityRoles: ['ROLE_SETUP_SIS_IMPORTS_API_EDIT', 'ROLE_SETUP_DISTRICT_VIEW'],
                params: { districtId },
                query: { m: 1 },
            }
        },
    },
    {
        /*
         * Temporary route to allow clients to edit some of the api details
         */
        path: '/:districtId/client-communication',
        name: 'client-communication',
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_SIS_IMPORTS_API_EDIT', 'ROLE_SETUP_DISTRICT_VIEW'],
            title: 'API Setup',
            sideNavSection: 'client-api-setup',
        },
        component: () =>
            import(
                /* webpackPrefetch: true */ '@/views/DistrictManagement/ClientView/SisImportProviderOverview.vue'
            ),
    },
    {
        path: '/district/add',
        name: 'district-profile-add',
        component: () =>
            import(/* webpackPrefetch: true */ '@/views/DistrictManagement/DistrictEditModal'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_DISTRICT_CREATE', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'District Add',
        },
        props: {
            edit: false,
            title: 'Add New District',
            value: true,
            backOnClose: true,
        },
    },
    {
        path: '/:districtId/setup',
        name: 'setup-overview',
        component: () => import(/* webpackPrefetch: true */ '@/views/SetupOverview'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'Setup',
        },
    },
    {
        path: '/:districtId/district',
        name: 'district',
        component: () =>
            import(/* webpackPrefetch: true */ '@/views/DistrictManagement/DistrictOverview'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_DISTRICT_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'District Overview',
            sideNavSection: 'district-management',
        },
        children: [
            {
                path: '/:districtId/district-profile',
                name: 'district-profile',
                components: {
                    default: () =>
                        import(
                            /* webpackPrefetch: true */ '@/views/DistrictManagement/DistrictProfile'
                        ),
                    actions: () =>
                        import(
                            /* webpackPrefetch: true */ '@/views/DistrictManagement/RolloverDialog'
                        ),
                },
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_SETUP_DISTRICT_VIEW'],
                    title: 'District Profile',
                    sideNavSection: 'district-management',
                },
            },
            {
                path: '/:districtId/sis-imports',
                name: 'sis-imports-default',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DistrictManagement/SisImportTemplate/SisImport'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: [],
                    title: 'District SIS Imports',
                    sideNavSection: 'district-management',
                },
                children: [
                    {
                        path: 'templates',
                        name: 'sis-imports',
                        title: 'Sis Imports Templates',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/SisImportTemplate/SisImportTemplates'
                            ),
                        meta: {
                            sideNavSection: 'district-management',
                            securityRoles: ['ROLE_SETUP_SIS_IMPORTS_TEMPLATES_VIEW'],
                        },
                    },
                    {
                        path: 'api-setup',
                        name: 'sis-imports-api-setup',
                        title: 'SIS API Setup',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/SisImportApi/ImportOverview'
                            ),
                        meta: {
                            sideNavSection: 'district-management',
                            securityRoles: ['ROLE_SETUP_SIS_IMPORTS_API_VIEW'],
                        },
                    },
                ],
            },
            {
                path: '/:districtId/sis-imports/:id(\\d+)',
                name: 'sis-imports-edit',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DistrictManagement/SisImportTemplate/SisImportForm'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT'],
                    breadcrumb: ['sis-imports'],
                    title: 'District SIS Imports Edit',
                    sideNavSection: 'district-management',
                },
            },
            {
                path: '/:districtId/sis-imports/create',
                name: 'sis-imports-create',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DistrictManagement/SisImportTemplate/SisImportForm'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE'],
                    breadcrumb: ['sis-imports'],
                    title: 'District SIS Imports Create',
                    sideNavSection: 'district-management',
                },
            },
            {
                path: '/:districtId/authentication',
                name: 'authentication-default',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DistrictManagement/Authentication/Authentication'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_SETUP_AUTH_VIEW'],
                    title: 'District Authentication',
                    sideNavSection: 'district-management',
                },
                children: [
                    {
                        path: 'configs',
                        name: 'authentication-configs',
                        title: 'District Authentication',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/Authentication/AuthenticationTable'
                            ),
                        meta: {
                            sideNavSection: 'district-management',
                            requiresAuth: true,
                            securityRoles: ['ROLE_SETUP_AUTH_VIEW'],
                        },
                    },
                    {
                        path: 'account-locking',
                        name: 'account-locking',
                        title: 'Account Locking',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/Authentication/AccountLocking'
                            ),
                        meta: {
                            sideNavSection: 'district-management',
                            requiresAuth: true,
                            securityRoles: ['ROLE_SETUP_AUTH_VIEW'],
                        },
                    },
                    {
                        path: 'password-complexity',
                        name: 'password-complexity',
                        title: 'Password Complexity',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/Authentication/PasswordComplexity'
                            ),
                        meta: {
                            sideNavSection: 'district-management',
                            requiresAuth: true,
                            securityRoles: ['ROLE_SETUP_AUTH_VIEW'],
                        },
                    },
                    {
                        path: 'locked-users',
                        name: 'locked-users',
                        title: 'Locked Users',
                        props: {
                            title: 'Locked Users',
                            query: {
                                'filter[locked]': 1,
                            },
                        },
                        meta: {
                            requiresAuth: true,
                            breadcrumb: ['authentication'],
                            title: 'Locked Users',
                            securityRoles: ['ROLE_ACCOUNT_SERVICE_VIEW'],
                            sideNavSection: 'district-management',
                        },
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DistrictManagement/Authentication/LockedUsers'
                            ),
                    },
                ],
            },
            {
                path: '/:districtId/export',
                name: 'export-overview',
                components: {
                    default: () =>
                        import(/* webpackPrefetch: true */ '@/views/ExportManager/ExportOverview'),
                    actions: () =>
                        import(/* webpackPrefetch: true */ '@/views/ExportManager/AddExport'),
                },
                meta: {
                    requiresAuth: true,
                    title: 'Export Overview',
                    sideNavSection: 'district-management',
                    securityRoles: ['ROLE_SETUP_EXPORT_PRODUCT_VIEW'],
                },
                children: [
                    {
                        path: 'add/:productId',
                        name: 'export-add',
                        props: true,
                        component: () =>
                            import(/* webpackPrefetch: true */ '@/views/ExportManager/Export'),
                        meta: {
                            requiresAuth: true,
                            title: 'Create Export',
                            sideNavSection: 'district-management',
                            securityRoles: ['ROLE_SETUP_EXPORT_PRODUCT_CREATE'],
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'export-edit',
                        props: true,
                        component: () =>
                            import(/* webpackPrefetch: true */ '@/views/ExportManager/Export'),
                        meta: {
                            requiresAuth: true,
                            title: 'Edit Export',
                            sideNavSection: 'district-management',
                            securityRoles: ['ROLE_SETUP_EXPORT_PRODUCT_EDIT'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/:districtId/site',
        name: 'site-overview',
        component: () => import(/* webpackPrefetch: true */ '@/views/SitesOverview'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_SITE_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'District Site Management',
            sideNavSection: 'site-management',
        },
    },
    {
        path: '/:districtId/user-management',
        name: 'user-management',
        component: () =>
            import(/* webpackPrefetch: true */ '@/views/DistrictUserManagment/UserManagement'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_MANAGEMENT_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'User Management',
            sideNavSection: 'user-management',
        },
        children: [
            {
                path: 'list',
                name: 'user-list',
                component: () =>
                    import(/* webpackPrefetch: true */ '@/views/DistrictUserManagment/UserList'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_USER_MANAGEMENT_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
                    title: 'User List',
                    sideNavSection: 'user-management',
                },
            },
            {
                path: 'account-sync-logs',
                name: 'account-sync-logs',
                component: () =>
                    import(
                        /*webpackPrefetch: true */ '@/views/DistrictUserManagment/AccountSyncErrors'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_ACCOUNT_SYNC_LOGS_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
                    title: 'Account Sync Logs',
                    sideNavSection: 'user-management',
                },
            },
        ],
    },
    {
        path: '/:districtId/user-management/create',
        name: 'staff-user-create',
        component: () =>
            import(/* webpackPrefetch: true */ '@/views/DistrictUserManagment/StaffForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_MANAGEMENT_CREATE', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['user-management'],
            title: 'User Create',
            sideNavSection: 'user-management',
        },
    },
    {
        path: '/:districtId/user-management/:id',
        name: 'staff-user-edit',
        component: () =>
            import(/* webpackPrefetch: true */ '@/views/DistrictUserManagment/StaffForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_MANAGEMENT_EDIT', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['user-management'],
            title: 'User Edit',
            sideNavSection: 'user-management',
        },
    },
    {
        path: '/:districtId/user-management/user-roles/:authXAccountId',
        name: 'user-roles-edit',
        component: () => import(/* webpackPrefetch: true */ '@/views/User/UserForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['user-management'],
            title: 'User Roles Edit',
            sideNavSection: 'user-management',
        },
    },
    {
        path: '/:districtId/district-instances',
        name: 'district-instances',
        component: () => import(/* webpackPrefetch: true */ '@/views/DistrictInstance'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_SETUP_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'District Instances',
            sideNavSection: 'district-instances',
        },
    },
    {
        path: '/:districtId/data-explorer',
        name: 'data-explorer',
        component: () => import(/* webpackPrefetch: true */ '@/views/DataExplorer/DataExplorer'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_DATA_EXPLORER_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'Data Explorer',
            sideNavSection: 'data-explorer',
        },
        children: [
            {
                path: '/:districtId/import-logs',
                name: 'import-logs',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ImportLog/ImportLogHeaders'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMPORT_LOG_VIEW'],
                    title: 'Import Logs',
                    sideNavSection: 'data-explorer',
                },
            },
            {
                path: '/:districtId/import-logs/results/:batchId',
                name: 'import-log-results',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ImportLog/ImportLogResults'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMPORT_LOG_VIEW'],
                    breadcrumb: ['import-logs'],
                    sideNavSection: 'data-explorer',
                    title: 'Import Log Results',
                },
            },
            {
                path: '/:districtId/import-logs/details/:batchId/:etlStatusId', // added batch id so breadcrumbs can find the route
                name: 'import-log-details',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ImportLog/ImportLogDetails'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMPORT_LOG_VIEW'],
                    breadcrumb: ['import-logs', 'import-log-results'],
                    sideNavSection: 'data-explorer',
                    title: 'Import Log Details',
                },
            },
            {
                path: '/:districtId/import-logs/error-logs/:batchId/:etlStatusId',
                name: 'import-error-logs',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ImportLog/ImportErrorLog'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMPORT_LOG_VIEW'],
                    breadcrumb: ['import-logs', 'import-log-results'],
                    sideNavSection: 'data-explorer',
                    title: 'Import Log Details',
                },
            },
            {
                path: '/:districtId/export-logs',
                name: 'export-logs',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ExportLog/ExportLogHeaders'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_EXPORT_LOG_VIEW'],
                    title: 'Export Logs',
                },
            },
            {
                path: '/:districtId/export-logs/results/:batchId',
                name: 'export-log-results',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ExportLog/ExportLogResults'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_EXPORT_LOG_VIEW'],
                    breadcrumb: ['export-logs'],
                    title: 'Export Log Results',
                },
            },
            {
                path: '/:districtId/export-logs/details/:batchId/:etlStatusId', // added batch id so breadcrumbs can find the route
                name: 'export-log-details',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/ExportLog/ExportLogDetails'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_EXPORT_LOG_VIEW'],
                    breadcrumb: ['export-logs', 'export-log-results'],
                    title: 'Export Log Details',
                },
            },
            {
                path: '/:districtId/table-viewer',
                name: 'table-viewer',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/TableViewer'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                    title: 'Table Viewer',
                    sideNavSection: 'data-explorer',
                },
                children: [
                    {
                        path: 'attendance',
                        name: 'attendance',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Attendance',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Attendance',
                            namespace: 'attendance',
                            columns: () => import('@/helpers/ag-grid/columnDef/attendance'),
                        },
                    },
                    {
                        path: 'gradebooks',
                        name: 'gradebooks',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Gradebooks',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Gradebooks',
                            namespace: 'gradebook',
                            columns: () => import('@/helpers/ag-grid/columnDef/gradebook'),
                        },
                    },
                    {
                        path: 'grading-periods',
                        name: 'grading-periods',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Grading Periods',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Grading Periods',
                            namespace: 'gradingPeriod',
                            columns: () => import('@/helpers/ag-grid/columnDef/gradingPeriod'),
                        },
                    },
                    {
                        path: 'roster',
                        name: 'roster',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Roster',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Roster',
                            namespace: 'schedule',
                            columns: () => import('@/helpers/ag-grid/columnDef/schedule'),
                        },
                    },
                    {
                        path: 'courses',
                        name: 'courses',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Courses',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Courses',
                            namespace: 'course',
                            columns: () => import('@/helpers/ag-grid/columnDef/course'),
                        },
                    },
                    {
                        path: 'contacts',
                        name: 'contacts',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Contacts',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Contacts',
                            namespace: 'contact',
                            columns: () => import('@/helpers/ag-grid/columnDef/contacts'),
                        },
                    },
                    {
                        path: 'sections',
                        name: 'sections',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Report Card',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Sections',
                            namespace: 'section',
                            columns: () => import('@/helpers/ag-grid/columnDef/sections'),
                        },
                    },
                    {
                        path: 'staff',
                        name: 'staff',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Staff',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Staff',
                            namespace: 'staff',
                            columns: () => import('@/helpers/ag-grid/columnDef/staff'),
                        },
                    },
                    {
                        path: 'students',
                        name: 'students',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Students',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Students',
                            namespace: 'student',
                            columns: () => import('@/helpers/ag-grid/columnDef/students'),
                        },
                    },
                    {
                        path: 'student-tags',
                        name: 'student-tags',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Student Tags',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Student Tags',
                            namespace: 'studentTag',
                            columns: () => import('@/helpers/ag-grid/columnDef/studentTags'),
                        },
                    },
                    {
                        path: 'student-programs',
                        name: 'student-programs',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Student Programs',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Student Programs',
                            namespace: 'studentProgram',
                            columns: () => import('@/helpers/ag-grid/columnDef/studentPrograms'),
                        },
                    },
                    {
                        path: 'sites',
                        name: 'sites',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Sites',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Sites',
                            namespace: 'site',
                            columns: () => import('@/helpers/ag-grid/columnDef/site'),
                        },
                    },
                    {
                        path: 'report-cards',
                        name: 'report-cards',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Report Cards',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Report Cards',
                            namespace: 'grade',
                            columns: () => import('@/helpers/ag-grid/columnDef/reportCard'),
                        },
                    },
                    {
                        path: 'transcripts',
                        name: 'transcripts',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Transcripts',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Transcripts',
                            namespace: 'transcript',
                            columns: () => import('@/helpers/ag-grid/columnDef/transcripts'),
                        },
                    },
                    {
                        path: 'behavior',
                        name: 'behavior',
                        component: () =>
                            import(
                                /* webpackPrefetch: true */ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Behavior',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Behavior',
                            namespace: 'behavior',
                            columns: () => import('@/helpers/ag-grid/columnDef/behavior'),
                        },
                    },
                    {
                        path: 'enrollment',
                        name: 'enrollment',
                        component: () =>
                            import(
                                /* webpackPrefetch: true*/ '@/views/DataExplorer/TableViewer/GenericTableViewer'
                            ),
                        meta: {
                            requiresAuth: true,
                            securityRoles: ['ROLE_TABLE_VIEWER_VIEW'],
                            title: 'Enrollment',
                            sideNavSection: 'data-explorer',
                        },
                        props: {
                            title: 'Enrollment',
                            apiAction: 'enrollment/get',
                            apiGetter: 'enrollment/getItems',
                            namespace: 'enrollment',
                            columns: () => import('@/helpers/ag-grid/columnDef/enrollment'),
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/services',
        name: 'imt-overview',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/ImtOverview'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'IMT Overview',
        },
        children: [
            {
                path: '/client',
                name: 'imt-clients',
                component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Clients/List'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMT_CLIENTS_VIEW'],
                    title: 'IMT Clients',
                },
            },
            {
                path: '/instance',
                name: 'imt-instances',
                component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Instances/List'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMT_INSTANCES_VIEW'],
                    title: 'IMT Instances',
                },
            },
            {
                path: '/authentication/:id?',
                name: 'imt-authentications',
                component: () =>
                    import(
                        /* webpackPrefetch: true */ '@/views/Imt/Authentications/Authentications'
                    ),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_IMT_AUTHENTICATIONS_VIEW'],
                    title: 'IMT Authentications',
                },
            },
            {
                path: '/account/:id?',
                name: 'accounts-list',
                component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Accounts/List'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_ACCOUNT_SERVICE_VIEW'],
                    title: 'Account Service',
                },
                props: true,
            },
        ],
    },
    {
        path: '/users-and-roles',
        name: 'user-and-roles',
        component: () => import(/* webpackPrefetch: true */ '@/views/User/UserRolesOverview'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_ILLUMINATE_EMPLOYEE'],
            title: 'Users and Roles',
        },
        children: [
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackPrefetch: true */ '@/views/User/UserList'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_ILLUMINATE_EMPLOYEE'],
                    title: 'Users',
                },
            },
            {
                path: '/roles',
                name: 'roles',
                component: () => import(/* webpackPrefetch: true */ '@/views/User/RoleList'),
                meta: {
                    requiresAuth: true,
                    securityRoles: ['ROLE_ILLUMINATE_EMPLOYEE'],
                    title: 'Roles',
                },
            },
        ],
    },
    {
        path: '/users/edit/:id',
        name: 'users-edit',
        component: () => import(/* webpackPrefetch: true */ '@/views/User/UserForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_ROLE_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['users'],
            title: 'User Roles Edit',
        },
    },
    {
        path: '/roles/create',
        name: 'roles-create',
        component: () => import(/* webpackPrefetch: true */ '@/views/User/RoleForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_ROLE_CREATE', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['roles'],
            title: 'User Roles Create',
        },
    },
    {
        path: '/roles/:id',
        name: 'role-edit',
        component: () => import(/* webpackPrefetch: true */ '@/views/User/RoleForm'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_USER_ROLE_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['roles'],
            title: 'Roles Edit',
        },
    },
    {
        path: '/client/create',
        name: 'client-create',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Clients/Form'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_CLIENTS_CREATE', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-clients'],
            title: 'Create Client',
        },
    },
    {
        path: '/client/:id',
        name: 'client-view',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Clients/Form'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_CLIENTS_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-clients'],
            title: 'View Client',
        },
    },
    {
        path: '/client/:id/edit',
        name: 'client-edit',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Clients/Form'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_CLIENTS_EDIT', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-clients'],
            title: 'Edit Client',
        },
    },
    {
        path: '/instance/create',
        name: 'instance-create',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Instances/Form'),
        // support optional query param with client id
        props: (route) => ({ query: route.query.clientId }),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_INSTANCES_CREATE', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-instances'],
            title: 'Create Instance',
        },
    },
    {
        path: '/instance/:id',
        name: 'instance-view',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Instances/Form'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_INSTANCES_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-instances'],
            title: 'View Instance',
        },
    },
    {
        path: '/instance/:id/edit',
        name: 'instance-edit',
        component: () => import(/* webpackPrefetch: true */ '@/views/Imt/Instances/Form'),
        meta: {
            requiresAuth: true,
            securityRoles: ['ROLE_IMT_INSTANCES_VIEW', 'ROLE_ILLUMINATE_EMPLOYEE'],
            breadcrumb: ['imt-instances'],
            title: 'View Instance',
        },
    },
    { path: '*', redirect: '/' }, // redirect to home for other routes
]
