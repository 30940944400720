import api from '@/api/export'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'EXPORT'
const API_DATA_KEY = 'export'
const API_DATA_BY_ID_KEY = 'id'
const defaultModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

export default {
    state: {
        ...defaultModule.state,
    },
    getters: {
        ...defaultModule.getters,
        getByProduct: (state) => {
            const items = state.items.reduce((acc, curr) => {
                acc[`${curr.productId}${curr.schoolYear}`] = curr

                return acc
            }, {})

            return Object.values(items)
        },
    },
    mutations: {
        ...defaultModule.mutations,
    },
    actions: {
        ...defaultModule.actions,
    },
    namespaced: true,
}
