import api from '@/api/api'

export default {
    async fetchBy({ id, lea }) {
        try {
            const resp = await api.get(`/api/district/${id}/sis-import-provider-configs`, {
                params: { lea },
            })

            return resp.data
        } catch (err) {
            throw `Error fetching district sis configs ${id}`
        }
    },
    async patch({ id, data, lea }) {
        try {
            const resp = await api.patch(`/api/district/${id}/api-provider`, data, {
                params: { lea },
            })

            return resp.data
        } catch (err) {
            throw `Error patching district ${id}`
        }
    },
}
