import api from '@/api/user-preference'

export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_COL_STATES = 'SET_COL_STATES'
export const SET_PAGE_SIZES = 'SET_PAGE_SIZES'
export const FETCHING = 'FETCHING_USER_PREFERENCES'
export const SAVING = 'SAVING_USER_PREFERENCES'

const state = {
    preferences: null,
    fetching: false,
    saving: false,
}
const getters = {
    getPreferences: (state) => state.preferences,
    getColStates: (state) => state.preferences.columnStates,
    getPageSizes: (state) => state.preferences.pageSizes,
    getSaving: (state) => state.saving,
    getFetching: (state) => state.fetching,
}

const mutations = {
    [SET_PREFERENCES]: (state, preferences) => {
        state.preferences = preferences
    },
    [SET_COL_STATES]: (state, colStates) => {
        state.preferences.columnStates = colStates
    },
    [SET_PAGE_SIZES]: (state, pageSizes) => {
        state.preferences.pageSizes = pageSizes
    },
    [FETCHING]: (state, fetching) => {
        state.fetching = fetching
    },
    [SAVING]: (state, saving) => {
        state.saving = saving
    },
}

const actions = {
    get: async ({ commit }) => {
        commit(FETCHING, true)

        const { data } = await api.get()

        commit(SET_PREFERENCES, data?.preferences || {})
        commit(FETCHING, false)
    },
    saveColState: async ({ commit, getters: { getColStates } }, { tblId, colState }) => {
        commit(SAVING, true)

        const data = { [tblId]: colState }

        Object.keys(getColStates).length === 0
            ? await api.postColStates(data)
            : await api.patchColStates(data)

        commit(SET_COL_STATES, { ...getColStates, ...data })
        commit(SAVING, false)
    },
    savePageSize: async ({ commit, getters: { getPageSizes } }, { tblId, pageSize }) => {
        commit(SAVING, true)

        const data = { [tblId]: pageSize }

        Object.keys(getPageSizes).length === 0
            ? await api.postPageSizes(data)
            : await api.patchPageSizes(data)

        commit(SET_PAGE_SIZES, { ...getPageSizes, ...data })
        commit(SAVING, false)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
