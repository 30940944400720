import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import { warningSnackbar, errorSnackbar } from '@/helpers/snackbar'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
/**
 * Array query params should use brackets
 *  example - /api/clients?search[key]=searchValue
 *
 * https://github.com/ljharb/qs#parsing-arrays
 */
axios.defaults.paramsSerializer = (params) => qs.stringify(params)

axios.interceptors.response.use(undefined, function (err) {
    return new Promise(async (resolve, reject) => {
        if (err.response) {
            const res = err.response

            if (res.status === 401) {
                await store.dispatch('auth/logoutUser')
            }
        }

        reject(err)
    })
})

export default axios

export const handleError = async (err, type = 'fetching', identifier = 'records') => {
    const { data } = err.response
    const { code, message } = data?.error
    const subtext = `An error occurred while ${type} ${identifier}. Reason: ${message}`

    if (code === 400) {
        await errorSnackbar({ subtext })

        return err.response
    }

    await warningSnackbar({ subtext })

    return err.response
}

export const FETCHING = 'fetching'
export const SAVING = 'saving'
export const DELETING = 'deleting'
