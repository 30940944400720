import { SHOW_SNACKBAR_MESSAGE } from '@/store/modules/snackbar'
import store from '@/store'

export const snackbar = async (payload) => {
    payload.display = true
    payload.delay = 3000
    await store.dispatch(`snackbar/${SHOW_SNACKBAR_MESSAGE}`, payload)
}

export const formSnackbar = async ({ isEdit, type, identifier }) => {
    const action = isEdit ? 'updated' : 'created'
    const subtext = `${type} ${action} (${identifier})` //'User updated (jmatlock@gmail.com)'

    await successSnackbar({ subtext })
}

export const successSnackbar = async ({ message, subtext }) => {
    await snackbar({
        message: message || 'Success',
        subtext,
        icon: 'fal fa-check',
        iconColor: 'success',
    })
}

export const infoSnackbar = async ({ message, subtext }) => {
    await snackbar({
        message: message || 'Information',
        subtext,
        icon: 'fal fa-info-circle',
        iconColor: 'info',
    })
}

export const warningSnackbar = async ({ message, subtext }) => {
    await snackbar({
        message: message || 'Warning',
        subtext,
        icon: 'fal fa-exclamation-triangle',
        //normally 'color lighten-#' would work here but it seems to break the icon display
        iconColor: 'lightyellow',
    })
}

export const errorSnackbar = async ({ message, subtext }) => {
    await snackbar({
        message: message || 'Error',
        subtext,
        icon: 'fal fa-exclamation-triangle',
        iconColor: 'error',
    })
}
