import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import routes from '@/router/routes'
import security from '@/helpers/security'
import store from '@/store/index'
import { errorSnackbar } from '@/helpers/snackbar'
import { confirmUnsavedChanges } from '@/helpers/shared'
import { appName } from '@/helpers/shared'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
    parseQuery(query) {
        return qs.parse(query)
    },
    stringifyQuery(query) {
        const result = qs.stringify(query)

        return result ? '?' + result : ''
    },
})

router.beforeEach(async (to, from, next) => {
    document.title = `${appName()} | Illuminate Education`
    next()
})

router.beforeEach(async (to, from, next) => {
    const user = store.getters['auth/getUser']

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!user.id) {
            next('login')
        } else {
            next()
        }
    } else next()
})

router.beforeEach(async (to, from, next) => {
    const user = store.getters['auth/getUser']

    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (user.id) {
            next('dashboard')
        } else {
            next()
        }
    } else next()
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.securityRoles.length > 0)) {
        const missingRoles = security.accessGranted(to)

        if (missingRoles.length === 0) {
            next()
        } else {
            const roles = missingRoles.join(', ')
            const subtext = `Access Denied. Requires the following role(s): ${roles}`
            await errorSnackbar({ subtext })

            //if there is more than one matched route, redirect to the first in that list
            //That should be the parent route.
            if (to.matched.length > 1) {
                next(to.matched[0])
            } else {
                next(from)
            }
        }
    } else next()
})

router.beforeEach(async (to, from, next) => {
    if (confirmUnsavedChanges() === false) {
        next(false) // cancel navigation
    } else next()
})

export default router
