export default {
    getItems: (state) => state.items,
    getItemById:
        (state) =>
        (id, field = 'id') =>
            state.items.find((item) => item[field] == id),
    getItemsById: (state) =>
        state.items.reduce((acc, cur) => {
            acc[cur.id] = cur

            return acc
        }, {}),
    getErrors: (state) => state.errors,
    getSaving: (state) => state.saving,
    getFetching: (state) => state.fetching,
    getPagination: (state) => state.pagination,
}
