import api from '@/api/api'
import base from '@/api/default'

export default {
    ...base('/api/products-enabled', 'Products Enabled'),
    async patch({ lea, productId, ...data }) {
        const resp = await api.patch(`/api/products-enabled/${productId}`, data, {
            params: { lea },
        })

        return resp.data
    },
}
