import api from '@/api/api'

export default {
    async fetchBy(params) {
        try {
            const resp = await api.get(`/api/possible-values`, { params })

            return resp.data
        } catch (err) {
            throw 'Error fetching SIS Import Template Possible Values.'
        }
    },
}
