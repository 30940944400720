/**
 * This module manages the results of rollovers between school years.
 *
 * The shape of parameters for templates and exports is:
 * {
 *  lea: "000000" // districts lea
 *  schoolYear: "2019" // this should be the source school year.
 *  executeRollover: true // should this be executed (probably always yes.)
 * }
 *
 * the staff request shape is
 * {
 *  lea: "000000" // districts lea
 *  schoolYear: "2019" // this should be the source school year.
 *  executeRollover: true // should this be executed (probably always yes.)
 *  rolloverAuthOnly: false // Auth in this case is IO Auth. Since it's being retired we always say false.
 * }
 */

import { rolloverExports, rolloverStaff, rolloverTemplates } from '@/api/rollover'

export const ROLLOVER_SET_KEY_RESULT = 'ROLLOVER_SET_KEY_RESULT'
export const ROLLOVER_PROCESSING = 'ROLLOVER_PROCESSING'
export const ROLLOVER_ACTIVE = 'ROLLOVER_ACTIVE'
export const ROLLOVER_FINISHED = 'ROLLOVER_FINISHED'
export const ROLLOVER_ERROR = 'ROLLOVER_ERROR'
export const ROLLOVER_RESET = 'ROLLOVER_RESET'

export const STAFF_KEY = 'staff'
export const TEMPLATES_KEY = 'templates'
export const EXPORTS_KEY = 'exports'

const initialState = () => ({
    // If there was an error doing a rollover.
    error: null,
    // are any records processing
    processing: {},
    // are rollovers active
    active: false,
    // rollover results as shaped by API.
    results: {},
})

const state = initialState()

const getters = {
    getResults: (state) => state.results,
    getActive: (state) => state.active,
    // check if any rollover is actively processing.
    getProcessing: (state) => Object.keys(state.processing).length > 0,
    // can be generally applied to vuetify types types.
    getStatusType: (state) => {
        const stillProcessing = Object.keys(state.processing).length > 0

        if (state.error) {
            return 'error'
        }

        if (state.active && !stillProcessing) {
            return 'success'
        }

        return 'info'
    },
}

const mutations = {
    [ROLLOVER_SET_KEY_RESULT](state, { key, result }) {
        state.results[key] = result
    },
    [ROLLOVER_ACTIVE](state, active) {
        state.active = active
    },
    [ROLLOVER_PROCESSING](state, subject) {
        state.processing = {
            ...state.processing,
            [subject]: true,
        }
    },
    [ROLLOVER_FINISHED](state, subject) {
        delete state.processing[subject]
        state.processing = { ...state.processing }
    },
    [ROLLOVER_ERROR](state, error) {
        state.error = error
    },
    [ROLLOVER_RESET](state) {
        // merge to preserve observers.
        Object.assign(state, initialState())
    },
}

const action = async (commit, params, api, key) => {
    commit(ROLLOVER_ACTIVE, true)
    commit(ROLLOVER_PROCESSING, key)
    try {
        const result = await api(params)
        commit(ROLLOVER_SET_KEY_RESULT, { key, result })
    } catch (e) {
        commit(ROLLOVER_ERROR, e)
    } finally {
        commit(ROLLOVER_FINISHED, key)
    }
}

const actions = {
    staff: async ({ commit }, params) => action(commit, params, rolloverStaff, STAFF_KEY),
    templates: async ({ commit }, params) =>
        action(commit, params, rolloverTemplates, TEMPLATES_KEY),
    exports: async ({ commit }, params) => action(commit, params, rolloverExports, EXPORTS_KEY),
    reset: ({ commit }) => {
        commit(ROLLOVER_RESET)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
