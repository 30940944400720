<template>
    <v-main>
        <v-container fluid>
            <router-view />
        </v-container>
        <snack-bar-message />
    </v-main>
</template>

<script>
import SnackBarMessage from '@/components/SnackBarMessage'

export default {
    name: 'LayoutBody',
    components: { SnackBarMessage },
}
</script>
