import api from '@/api/api'

export default {
    async validateApi({ data }) {
        try {
            const resp = await api.post(`/api/aeries/schools`, {
                baseAPI: data.sisApiUrl,
                cert: data.sisApiKey,
            })
            // if errors exist in the api call
            if (resp.data.errors) {
                return false
            }

            return true
        } catch (e) {
            return false
        }
    },
}
