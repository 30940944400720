import api from '@/api/school-year'
import baseModule from '@/store/shared/graphql'

const STORE_TYPE = 'SCHOOL_YEAR'

const API_DATA_BY_ID_KEY = 'schoolYear'

const API_DATA_KEY = 'schoolYear'

const SELECT_YEAR = 'SELECT_YEAR'

const defualtModule = baseModule(STORE_TYPE, API_DATA_BY_ID_KEY, API_DATA_KEY, api)

const state = {
    ...defualtModule.state,
    selected: null,
}

const getters = {
    ...defualtModule.getters,
    getSelected: (state) => state.selected,
}

const mutations = {
    ...defualtModule.mutations,
    [SELECT_YEAR]: (state, year) => {
        state.selected = year
    },
}

const actions = {
    ...defualtModule.actions,
    select: ({ commit }, year) => {
        commit(SELECT_YEAR, year)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
