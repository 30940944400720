export const SET_ITEM = '_SET_ITEM'
export const SET_ITEMS = '_SET_ITEMS'
export const SET_METADATA = '_SET_METADATA'
export const FETCHING = '_FETCHING'
export const SAVING = '_SAVING'
export const ERRORS = '_ERRORS'
export const CLEAR_ERRORS = '_CLEAR_ERRORS'

export default (STORE_TYPE) => {
    return {
        SET_ITEM: `${STORE_TYPE}${SET_ITEM}`,
        SET_ITEMS: `${STORE_TYPE}${SET_ITEMS}`,
        SET_METADATA: `${STORE_TYPE}${SET_METADATA}`,
        FETCHING: `${STORE_TYPE}${FETCHING}`,
        SAVING: `${STORE_TYPE}${SAVING}`,
        ERRORS: `${STORE_TYPE}${ERRORS}`,
        CLEAR_ERRORS: `${STORE_TYPE}${CLEAR_ERRORS}`,
    }
}
