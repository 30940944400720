import api, { handleError, FETCHING, SAVING, DELETING } from '@/api/api'

const API_TYPE = 'instance'
const BASE_URL = '/api/ied/instances'

export default {
    async cget(params) {
        try {
            return await api.get(`${BASE_URL}`, { params })
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async get(id) {
        try {
            return await api.get(`${BASE_URL}/${id}`)
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async post({ data }) {
        try {
            return await api.post(`${BASE_URL}`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async patch({ id, data }) {
        try {
            return await api.patch(`${BASE_URL}/${id}`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async remove({ id }) {
        try {
            return await api.delete(`${BASE_URL}/${id}`)
        } catch (err) {
            return await handleError(err, DELETING, API_TYPE)
        }
    },
    async patchStatus({ id, data }) {
        try {
            return await api.patch(`${BASE_URL}/${id}/status`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async postSandbox(data) {
        try {
            return await api.post(`${BASE_URL}/${data.id}/sandbox`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async getProducts(id) {
        try {
            return await api.get(`${BASE_URL}/${id}/products`)
        } catch (err) {
            return await handleError(err, FETCHING, API_TYPE)
        }
    },
    async addProducts({ instanceId, data }) {
        try {
            return await api.post(`${BASE_URL}/${instanceId}/products`, data)
        } catch (err) {
            return await handleError(err, SAVING, API_TYPE)
        }
    },
    async deleteProducts({ instanceId, data }) {
        try {
            return await api.delete(`${BASE_URL}/${instanceId}/products`, {
                data,
            })
        } catch (err) {
            return await handleError(err, DELETING, API_TYPE)
        }
    },
}
