const state = {
    items: [
        { name: '' },
        { name: 'Pre-School' },
        { name: 'Elementary School' },
        { name: 'Middle & K-8 School' },
        { name: 'High School' },
        { name: 'Continuation School' },
        { name: 'Adult Education Facility' },
        { name: 'Special Education Facility' },
        { name: 'Other School' },
        { name: 'Closed' },
    ],
}

const getters = {
    getSchoolTypes: (state) => state.items,
}

export default {
    state,
    getters,
    namespaced: true,
}
