import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#2B3963',
                success: '#5BE893',
                error: '#F24b40',
                info: '#539AF8',
                danger: '#bf0f00',
                lightyellow: '#FFFB82',
            },
        },
    },
})
