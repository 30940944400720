import api from '@/api/imt/authentications'
import baseModule from '@/store/shared/rest'

export const SET_FIELD = 'UPDATE_FIELD'
export const SET_DRAFT = 'SET_DRAFT'
export const SET_SUB_FIELD = 'SET_SUB_FIELD'

const STORE_TYPE = 'AUTHENTICATIONS'
const API_DATA_KEY = 'items'

const defaultModule = baseModule(STORE_TYPE, API_DATA_KEY, api, {
    updateOnPost: false,
    updateOnPatch: false,
})

const state = {
    ...defaultModule.state,
}

const getters = {
    ...defaultModule.getters,
    getAuthByField: (state) => (fieldName, fieldValue) =>
        state.items.find((item) => item[fieldName] === fieldValue),
}

const mutations = {
    ...defaultModule.mutations,
}

const actions = {
    ...defaultModule.actions,
    fetchMetadata: async ({ commit }, params) => {
        return await api.fetchMetadata(params)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
