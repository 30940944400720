import api from '@/api/api'
import defaultApi from '@/api/default'

const ENDPOINT = '/accounts'
const CLIENT_HEADER = 'X-Ied-Client-Id'

const SUBJECT = 'accounts'

export default {
    ...defaultApi(ENDPOINT, SUBJECT),
    async cget(params, { clientId }) {
        return await api.get(`/api/accounts`, {
            params,
            headers: {
                [CLIENT_HEADER]: clientId,
            },
        })
    },
    async get({ id, clientId }) {
        return await api.get(`/api/accounts/${id}`, {
            headers: {
                [CLIENT_HEADER]: clientId,
            },
        })
    },
    async patch({ accountId, clientId, data }) {
        return await api.patch(`/api/accounts/${accountId}`, data, {
            headers: {
                [CLIENT_HEADER]: clientId,
            },
        })
    },
}
