export const SHOW_SNACKBAR_MESSAGE = 'SHOW_SNACKBAR_MESSAGE'
export const MESSAGE_DISPLAY = 'MESSAGE_DISPLAY'
const MESSAGE_TEXT = 'MESSAGE_TEXT'
const MESSAGE_DELAY = 'MESSAGE_DELAY'
const MESSAGE_COLOR = 'MESSAGE_COLOR'
const MESSAGE_DISMISSABLE = 'MESSAGE_DISMISSABLE'
const MESSAGE_ICON = 'MESSAGE_ICON'
const MESSAGE_ICON_COLOR = 'MESSAGE_ICON_COLOR'
// I don't want to refactor the whole block, but if message text was message title, this would be message text.
const MESSAGE_SUBTEXT = 'MESSAGE_SUBTEXT'
const MESSAGE_TOP = 'MESSAGE_TOP'

const defaultState = () => ({
    display: false,
    message: '',
    delay: 4000,
    dismissable: true,
    color: 'primary',
    icon: '',
    iconColor: 'success',
    subtext: '',
    top: false,
})

export default {
    state: defaultState(),
    getters: {
        getMessageText: (state) => state.message,
        getDelay: (state) => state.delay,
        getDismissable: (state) => state.dismissable,
        getColor: (state) => state.color,
        getDisplay: (state) => state.display,
        getMessageSubtext: (state) => state.subtext,
        getIcon: (state) => state.icon,
        getIconColor: (state) => state.iconColor,
        getTop: (state) => state.top,
    },
    mutations: {
        [MESSAGE_DISMISSABLE]: (state, dismissable) => {
            state.dismissable = dismissable
        },
        [MESSAGE_DELAY]: (state, delay) => {
            state.delay = delay
        },
        [MESSAGE_TEXT]: (state, message) => {
            state.message = message
        },
        [MESSAGE_DISPLAY]: (state, display) => {
            state.display = display
        },
        [MESSAGE_COLOR]: (state, color) => {
            state.color = color
        },
        [MESSAGE_ICON]: (state, icon) => {
            state.icon = icon
        },
        [MESSAGE_ICON_COLOR]: (state, iconColor) => {
            state.iconColor = iconColor
        },
        [MESSAGE_SUBTEXT]: (state, subtext) => {
            state.subtext = subtext
        },
        [MESSAGE_TOP]: (state, top) => {
            state.top = top
        },
    },
    actions: {
        [SHOW_SNACKBAR_MESSAGE]: (
            { commit },
            { display, dismissable, delay, message, color, subtext, icon, iconColor, top }
        ) => {
            const baseState = defaultState()
            commit(MESSAGE_DISMISSABLE, dismissable || baseState.dismissable)
            commit(MESSAGE_DELAY, delay || baseState.delay)
            commit(MESSAGE_TEXT, message || baseState.message)
            commit(MESSAGE_COLOR, color || baseState.color)
            commit(MESSAGE_DISPLAY, display || baseState.display)
            commit(MESSAGE_SUBTEXT, subtext || baseState.subtext)
            commit(MESSAGE_ICON, icon || baseState.icon)
            commit(MESSAGE_ICON_COLOR, iconColor || baseState.iconColor)
            commit(MESSAGE_TOP, top !== null ? top : baseState.top)
        },
    },
    namespaced: true,
}
